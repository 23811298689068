.mainContainer {
    height: 100%;
    margin: 0 0 95px;
    min-width: 375px;
    max-width: 425px;
    text-align: center;

    .commentTitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #1B1D22;
        text-align: left;
        padding: 10px 20px 0 20px;
    }

    .comment {
        padding: 20px 0;

        .commentArea {
            padding: 0 10px;
            width: 100%;
        }

        .commentBox {
            margin-top: 10px;
        }

        .commentText {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            text-align: left;
        }

        .commentAuthor {
            margin-top: 5px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            width: 100%;
            display: flex;
        }

        .commentImageBox {
            width: 100%;
        }

        .commentImage {
            width: 100%;
        }
    }
    .commentHint {
        padding: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #818181;
    }

    .hintBold {
        font-weight: 700;
        color: #1B1D22;
    }
}

.lightPurpleContainer {
    .commentHint {
        color: #000000;
    }

    .hintBold {
        color: #B826F6;
    }

}

.yellowContainer {
    .commentTitle {
        color: #FFFFFF;
    }

    .comment {
        .commentText {
            color: #FFFFFF;
        }

        .commentAuthor {
            color: #FFFFFF;
        }
    }

    .hintBold {
        color: #FFFFFF;
    }
}