@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@500&family=Roboto:wght@100;200;300;400;500;600;700;800&display=swap');
@import "./variables.scss";

body {
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
}

%flex {
	display: flex;
	align-items: center;
}

.flex-row {
	@extend %flex;
	flex-direction: row;
}

.flex-column {
	@extend %flex;
	flex-direction: column;
}

.flex-between {
	@extend %flex;
	flex-direction: row;
	justify-content: space-between;
}

h1 {
	font-size: 26px;
	font-weight: 700;
	line-height: 31px;
}

h2 {
	font-size: 22px;
	font-weight: 800;
	line-height: 28px;
}

h3 {
	font-weight: 700;
	font-size: 17px;
	line-height: 16px;
	text-transform: uppercase;
}

.container {
	height: auto;
}

.wrapper {
	height: 100%;
	min-width: 375px;
	max-width: 460px;
	margin: 0 auto;
}

.cardsContainer {
	width: 100%;
	flex-direction: column;
	align-items: center;
	margin-top: auto;
}

.cardsWrapper {
	width: 100%;
	margin-bottom: 35px;
	align-self: center;
}

.buttonContainer {
	width: 100%;
    margin-top: auto;
}

.componentSubtitle {
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	margin-top: 8px;
	color: $component-subtitle-color;
}

.cardTitle {
	font-size: 15px;
    font-weight: 400;
    line-height: 18px;
	color: $card-title-color;
}

.cardContainer {
	position: relative;
	border: 0.1px solid #d3d3d3;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
	cursor: pointer;

	&:active::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px; 
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }
}

.pinkCardContainer {
	&:active::before {
		border: 1px solid #E15768;
		box-shadow: $box-shadow-active;
	}
}

.purpleCardContainer {
	&:active::before {
		border: 1px solid #7D8BF6;
		box-shadow: 0 0 10px -2px rgba(202, 207, 247, 0.79);
	}
}

.lightPurpleCardContainer {
	&:active::before {
		border: 1px solid #B826F6;
		box-shadow: 0 0 10px -2px rgba(216, 175, 234, 0.79);
	}
}

.yellowCardContainer {
	&:active::before {
		border: 1px solid #DAFF21;
		box-shadow: unset;
	}
}

.resultSubtitle {
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	color: #1B1D22;
}

.questionContainer {
	width: 100%;
	height: 382px;
	padding: 0 20px 0;
	font-family: 'Roboto', sans-serif;
	background: linear-gradient(180deg, rgba(17, 17, 23, 0) 13.02%, #111117 100%);

	.questionTitle {
		width: 100%;
		font-weight: 700;
		font-size: 47px;
		line-height: 55px;
		color: #FFFFFF;
		margin-bottom: 31px;
	}

	.buttonsContainer {
		position: fixed;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		bottom: 12px;
		left: 20px;
		width: calc(100% - 40px);

		.button {
			height: 56px;
			text-align: center;
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
			border-radius: $border-radius;
			border: 1px solid #FFFFFF;
			cursor: pointer;
		}
	
		.firstButton {
			width: 119px;
			color: #FFFFFF;
			background-color: transparent;
		}
	
		.secondButton {
			width: 203px;
			color: #000000;
			background-color: #FFFFFF;
		}
	}
}


@media screen and (min-width: 500px) {
    .container {
		height: 700px;
	}
}

.pulse-button__rings {
	border: 1px solid #E15768;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
	border-radius: 8px;
	animation: pulse_1 2s infinite;
	z-index: 1;
}

.pulse-button__rings:nth-child(2) {
	animation: pulse 2s 0.7s infinite;
}

.pulse-button__rings:nth-child(3) {
	animation: pulse 2s 1.4s infinite;
}

@keyframes pulse {
	from {
		opacity: 1;
		transform: scale(1, 1)
	}

	to {
		opacity: 0;
		transform: scale(1.04, 1.4);
	}
}
