.mainContainer {
    justify-content: space-between;

    .inputType {
        font-weight: 700;
        font-size: 26px;
        text-align: center;
        color: #1E1E27;
    }
}

.yellowContainer {
    .inputType {
        color: #FFFFFF;
    }
}
