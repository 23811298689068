.stepper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
    width: 273px;
    margin: 0 auto;

    .stepper__step {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        &:first-child {
            display: none;
        }

        .labelContainer {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
        }

        .stepContainer {
            position: relative;
            width: 40px;
            height: 40px;
            border: 2px solid #E8E8E8;
            background-color: #ffffff;
            border-radius: 50%;
            z-index: 4;

            img {
                width: 16px;
                height: 16px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }

        .completedStep {
            width: 41px;
            height: 41px;
            border: double 4px transparent;
            border-radius: 50%;
            background-image: linear-gradient(white, white), radial-gradient(circle at center top, #fd6c59,#dd3787);
            background-origin: border-box;
            background-clip: content-box, border-box;
            display: inline-block;
            z-index: 4;
        }

        .pinkCompletedStep {
            background-image: linear-gradient(white, white), radial-gradient(circle at center top, #fd6c59,#dd3787);
        }

        .purpleCompletedStep {
            background-image: linear-gradient(white, white), radial-gradient(circle at center top, #7D8BF6, #7D8BF6);
        }

        .lightPurpleCompletedStep {
            background-image: linear-gradient(white, white), radial-gradient(circle at center top, #B826F6, #B826F6);
        }
        .yellowCompletedStep {
            background-image: linear-gradient(white, white), radial-gradient(circle at center top, #DAFF21, #DAFF21);
        }

        .linearProgress {
            height: 4px;
            position: absolute !important;
            left: -2px;
            right: 39px;
            top: 51%;
            transform: translate(0, -50%);

            background-color: #fff !important;

            &::after {
                content: '' !important;
                transform: translate(0, -50%);
                width: 100%;
            }
        }
    }
}
