.mainContainer {
    padding: 0 20px;
    margin-top: 23px;
    text-align: center;

    .marginTitle {
        padding: 0 34px;
        margin-bottom: 140px;
    }

    .imageContainer {
        width: 100%;
    }

    .checkboxContainer {
        margin: 39px 0 0;

        span {
            margin-left: 10px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }
    }
}