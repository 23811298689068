@import "/src/styles/variables";

.cardContainer {
    position: relative;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 75px;
    padding: 14px 8px 14px 21px;
    background-color: #fff;
    border: 1px solid #BBBBBB;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 17px;

    .wrapper {
        width: 100%;
    }

    .header {
        font-family: 'Roboto', sans-serif;
        justify-content: center;
        width: 100%;
        height: 18px;
        background: #BBBBBB;
        border-radius: 7px 7px 0px 0px;

        img {
            width: 11px;
            height: 11px;
            margin-right: 12px;
        }

        p {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
        }
    }

    .priceContainer {
        margin-left: 13px;
        align-items: flex-start;

        .title {
            font-weight: 800;
            font-size: 15px;
            line-height: 17px;
            color: #818181;
            text-transform: uppercase;
            @media screen and (max-width: 391px) {
                             font-size: 13px !important;
                         }
        }
    }

    .oldPrice {
        position: relative;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #818181;
        @media screen and (max-width: 391px) {
                                     font-size: 11px !important;
                                 }

        span {
            position: absolute;
            width: calc(100% + 4px);
            border: 0.5px solid #8F8E94;
            top: 50%;
            left: 0;
            transform: translate(-2px, -50%);
        }
    }

    .newPrice {
        margin-left: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #818181;
    }

    .rightContent {
        align-items: flex-end;

        .oldPricePerDay {
            margin-right: 4px;
            
            span {
                border-color: #FF0000;
            }
        }

        .perDayContainer {
            width: 113px;
            height: 46px;
            padding: 3px 8px 3px 19px;
            background-image: url(../../../../assets/images/grey-price.svg);
            color: #818181;

            .dollar {
                margin: 4px 3px 0 0;
                align-self: flex-start;
                font-weight: 900;
                font-size: 15px;
                line-height: 17px;
            }

            .beforeDot {
                font-weight: 800;
                font-size: 40px;
                line-height: 17px;
            }

            .subtitleContainer {
                margin-top: 5px;
                align-items: flex-start;

                .afterDot {
                    font-weight: 900;
                    font-size: 15px;
                    line-height: 17px;
                }
    
                .subtitle {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
    }
}

.popular {
    padding: 0; 
    height: 81px !important;

    .wrapper {
        padding: 8px 8px 7px 21px;
    }

}

.activeCard {
    border: 1px solid #E15768;

    .header {
        background: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
    }

    .priceContainer {
        .title {
            color: #000000;
        }
    }

    .oldPricePerDay, .perDayContainer {
        color: #000000 !important;
    }

    .perDayContainer {
        background-image: url(../../../../assets/images/active-price.svg) !important;
    }
}

.campaignCase {
    .saleHeader {
        margin: 0 20px;
        width: 100%;
        justify-content: space-between;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
        }

        .imageSaleStar {
            width: 10px;
            height: 10px;
        }
    }
}

.lightGreenCampaignCase {

    &.activeCard {
        border: 1px solid #57A97B;

        .header {
            background: #57A97B;
        }

        .perDayContainer {
            background-image: url(../../../../assets/images/active-price-lightGreen.svg) !important;
        }
    }

    .header {
        span {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
        }
    }
}

.lightGreenActivePopular {
    border: 1px solid #57A97B;

    .perDayContainer {
        background-image: url(../../../../assets/images/active-price-lightGreen.svg) !important;
    }

    .header {
        background: #57A97B;

        span {
            color: #FFFFFF;
        }
    }

    .saleHeader {
        background-color: #57A97B;

        span {
            color: #FFFFFF;
        }
    }
}

.bfActivePopular {
    border: 1px solid #000000;

    .perDayContainer {
        background-image: url(../../../../assets/images/active-price-bf.svg) !important;
    }

    .header {
        background: #000000;
    }

    .saleHeader {
        background-color: #000000;

        span {
            color: #FDE007;
        }
    }
}

.nyActivePopular {
    border: 1px solid #2D603F;

    .perDayContainer {
        background-image: url(../../../../assets/images/active-price-ny.svg) !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .header {
        background: #2D603F;
    }

    .saleHeader {
        background-color: #2D603F;

        span {
            color: #FFFFFF;
        }
    }
}

.lightPurpleCampaignCase {
    &.activeCard {
        border: 1px solid #76FB4C;

        .header {
            background: #76FB4C;

            span {
                color: #000000;
            }
        }

        .perDayContainer {
            background-image: url(../../../../assets/images/active-price-halloween.svg) !important;
        }
    }

    .header {
        span {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
        }
    }
}

.lightPurpleActivePopular {
    border: 1px solid #76FB4C;

    .perDayContainer {
        background-image: url(../../../../assets/images/active-price-lightGreen.svg) !important;
    }

    .header {
        background: #76FB4C;

        span {
            color: #FFFFFF;
        }
    }

    .saleHeader {
        background-color: #76FB4C;

        span {
            color: #000000;
        }
    }
}

.yellowCampaignCase {
    &.activeCard {
        border: 1px solid #DAFF21;

        .header {
            background: #DAFF21;

            span {
                color: #000000;
            }
        }

        .perDayContainer {
            background-image: url(../../../../assets/images/active-price-cf-bf.svg) !important;
        }
    }

    .header {
        span {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
        }
    }
}

.yellowActivePopular {
    border: 1px solid #DAFF21;

    .perDayContainer {
        background-image: url(../../../../assets/images/active-price-cf-bf.svg) !important;
    }

    .header {
        background: #DAFF21;

        span {
            color: #FFFFFF;
        }
    }

    .saleHeader {
        background-color: #DAFF21;

        span {
            color: #000000;
        }
    }
}