@import '/src/styles/variables.scss';

.cardContainer {
    width: 100%;
    height: 140px;
    padding: 10px;
    align-items: center;
    justify-content: center;

    .cardIcon {
        img {
            width: 50px;
            height: 50px;
        }
    }

    .cardTitle {
        padding: 10px 0;
        font-size: 15px;
        line-height: 18px;
        color: #1E1E27;
    }
}

.activeCard {
    &::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px; 
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }
}

.cardMessage {
    width: 100%;
    text-align: start;
    padding: 0 75px 0 58px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #BBBBBB;
}