@import '/src/styles/variables';

.buttonContainer {
    position: relative;
    width: calc(100% - 40px);
    height: 48px;
    padding: 0 24px;
    border: none;
    border-radius: $border-radius;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    line-height: 16px;
    background: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
    box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3) !important;
    cursor: pointer;
    z-index: 2;

    .pulseButtonRings {
        border: 1px solid #E15768;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        animation: pulse_1 2s infinite;
        z-index: 1;
    }

    .pulseButtonRings:nth-child(2) {
        animation: pulse 2s 0.7s infinite;
    }

    .pulseButtonRings:nth-child(3) {
        animation: pulse 2s 1.4s infinite;
    }

    @keyframes pulse {
        from {
            opacity: 1;
            transform: scale(1, 1)
        }

        to {
            opacity: 0;
            transform: scale(1.04, 1.4);
        }
    }
}

.smallButton {
    width: 120px;
    height: 34px;
    font-size: 15px;
    line-height: 18px;
    padding: 0;
}

.lightGreenCampaign {
    background: #57A97B;
    border: 2px solid #FFFFFF;
    box-shadow: unset !important;
    width: 100%;

    .pulseButtonRings {
        border: 1px solid #57A97B;
    }
}

.lightPurpleContainer {
    background: #B826F6;
    border: unset;

    .pulseButtonRings {
        border: 1px solid #B826F6;
    }
}

.yellowContainer {
    background: #DAFF21;
    border: unset;
    color: #000000;

    .pulseButtonRings {
        border: 1px solid #DAFF21;
    }
}
