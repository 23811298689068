.labelRow {
    margin-top: 15px;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;

    .label {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: #000000;
        text-align: start;
        width: 100%;
    }
}

.yellowContainer {
    .label {
        color: #FFFFFF;
    }
}