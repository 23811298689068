@import "/src/styles/variables.scss";

.swiperContainer {
    width: 100% !important;
    margin-bottom: 20px;
    max-width: 420px;
    padding: 0 30px;

    img {
        border-radius: $border-radius;
    }

    .swiperSlide {
        border-radius: 8px;
    }

    .swiperSlideWithoutBorder {
        .swiperImage {
            max-width: 290px;
        }
    }

    .swiperSlideBorder {
        width: 275px;
        height: 257px;
        border: 0.4px solid #818181;
        box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.12);
    }
}

.sliderTitle {
    margin: 20px 0 30px 0;
    font-weight: 700;
    font-size: 17px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.swiperContainerWithoutBorder {
    padding: 0 50px;
}