@import '/src/styles/variables';

.mainContainer {
    position: relative;
    width: calc(100% - 40px);
    height: 268px;
    margin: 43px 20px;
    padding: 7px 8px;
    background-color: #E15768;
    border-radius: 8px;

    .wrapper {
        width: 100%;
        height: 100%;
        border: 2px solid #FFFFFF;
        border-radius: 8px;

        h2 {
            padding: 32px 61px 19px;
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            color: #fff;
        }

        p {
            padding: 0 21px;
            text-align: start;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
        }
    }

    img {
        position: absolute;
        bottom: 14px;
        right: 12px;
        width: 43px;
        height: 41px;
    }
}


.lightGreenCampaign {
    margin: 40px 0;
    background-color: #E7E7E7;
    width: 100%;
    height: 260px;

    .wrapper {
        padding: 15px;

        h2 {
            line-height: 23px;
            text-align: center;
            color: #818181;
            padding: 15px 10px 10px 10px;
        }

        .description {
            display: flex;
            flex-direction: row;
            gap: 10px;

            p {
                padding: 0;
                color: #818181;
            }

            .guaranteeImage {
                min-height: 127px;
                min-width: 107px;
                max-height: 130px;
                max-width: 110px;

                img {
                    position: unset;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
