.mainContainer {
    text-align: start;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 15px 0 15px;
    min-width: 375px;
    max-width: 425px;

    .title {
        font-weight: 700;
        font-size: 26px;
        line-height: 28px;
        color: #1B1D22;
        padding: 0 5px;
    }

    .progress {
        width: 100%;
        margin-top: 5px;
    }

    .progressBar {
        appearance: none;
        width: 100%;
        height: 10px;
        border-radius: 100px;
        background: pink;

        &::-webkit-progress-bar {
            background: pink;
            border-radius: 100px;
        }

        &::-webkit-progress-value{
            background-color: #E15768;
            border-radius: 100px;
         }
        &::-moz-progress-bar {
            background-color: #E15768;
            border-radius: 100px;
        }
    }

    .pinkProgressBar {
        background: pink;

        &::-webkit-progress-bar {
            background: pink;
        }

        &::-webkit-progress-value{
            background-color: #E15768;
        }
        &::-moz-progress-bar {
            background-color: #E15768;
        }
    }

    .purpleProgressBar {
        background: #bec5fb;

        &::-webkit-progress-bar {
            background: #bec5fb;
        }

        &::-webkit-progress-value{
            background-color: #7D8BF6;
        }
        &::-moz-progress-bar {
            background-color: #7D8BF6;
        }
    }

    .lightPurpleProgressBar {
        background: #FFFFFF;

        &::-webkit-progress-bar {
            background: #FFFFFF;
        }

        &::-webkit-progress-value{
            background-color: #B826F6;
            border-color: #B826F6;
        }
        &::-moz-progress-bar {
            background-color: #B826F6;
            border-color: #B826F6;
        }
    }

    .yellowProgressBar {
        background: #7c8f21;

        &::-webkit-progress-bar {
            background: #7c8f21;
        }

        &::-webkit-progress-value{
            background-color: #DAFF21;
            border-color: #DAFF21;
        }
        &::-moz-progress-bar {
            background-color: #DAFF21;
            border-color: #DAFF21;
        }
    }

    .labelRow {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .activeLabel {
            text-align: start;
            width: 100%;
            font-weight: 600;
            height: 28px;
            color: #000000;
        }

        .inactiveLabel {
            text-align: start;
            width: 100%;
            height: 28px;
            color: #818181;
        }

        .finishedLabel {
            text-align: start;
            width: 100%;
            font-weight: 600;
            height: 28px;
            color: #818181;
        }

        .activePercent {
            text-align: end;
            height: 20px;
        }

        .progressDone {
            width: 20px;
            height: 20px;
            text-align: end;
        }
    }

    .infoContainer {
        margin-top: 20px;
        text-align: center;
        width: 100%;

        .infoImage {
            padding-top: 15px;
            font-size: 32px;
            color: #E15768;
            font-weight: 700;

            .image {
                img {
                    width: 30%;
                    margin: 10px auto;
                }
            }
        }

        .infoTitle {
            font-weight: 700;
        }
    }

    .commentsContainer {
        margin-top: 20px;
        padding: 0 5px;
        width: 100%;

        .greenSwiper {
            --swiper-pagination-color: #818181;
            --swiper-pagination-bullet-size: 6px;
            height: 195px;
        }

        .swiperSlide {
            max-height: 195px;
        }

        .commentContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 165px;
            padding: 15px;
            background: #F2F3FE;
            border-radius: 8px;

            .imageContainer {
                margin-bottom: 10px;

                .stars {
                    img {
                        margin: 0 auto;
                        width: 50%;
                    }
                }
            }

            p {
                font-weight: 500;
                font-size: 17px;
                line-height: 21px;
                text-align: center;
                color: #1B1D22;
            }

            .authorContainer {
                margin-top: 10px;

                span {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    text-align: center;
                    color: #000000;
                    opacity: 0.4;
                    margin: 0 auto;
                }
            }
        }
    }
}

.lightPurpleContainer {
    .labelRow {
        .inactiveLabel {
            color: #000000;
        }
    }
}

.yellowContainer {
    .title {
        color: #FFFFFF;
    }

    .labelRow {
        .inactiveLabel {
            color: #FFFFFF;
        }

        .activeLabel {
            color: #FFFFFF;
        }

        .activePercent {
            color: #FFFFFF;
        }
    }

    .infoContainer {
        .infoTitle {
            color: #FFFFFF;
        }
    }
}
