.mainContainer {
    height: 100%;
    margin: 23px 0 95px;
    text-align: center;

    .cardTitle {
        padding: 0 80px;
    }

    .subtitle {
        padding: 0 100px;
    }

    .circleContainer {
        position: relative;
        margin: 33px 0 55px;
        width: 175px;
        height: 175px;
        border-radius: 50%;
        background: #FBFBFB;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1) !important;

        .circleImage {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: transparent;

            img {
                width: 46.5px;
                height: 46.5px;
            }
        }

        svg {
            position: relative;
            width: 183px;
            height: 183px;
            transform: rotate(-90deg) translate(4px, -4px);
            
            circle {
              width: 100%;
              height: 100%;
              fill: none;
              stroke-width: 8;
              stroke-linecap: round;
              transform: translate(4px, 4px);
              stroke-dasharray: 550;
              transition: all 2s;
            }
          }        
    }

    .circleTitle {
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
    }

    .circleSubtitle {
        margin: 11px 50px 33px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #818181;
    }
}

.sliderLabels {
    width: 100%;
    padding: 0 20px;
    margin: 11px 0 37px;

    span {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
    }
}

.sliderContainer {
    width: 100%;
    margin-top: auto;
}

.lightPurpleContainer {
    .subtitle {
        color: #000000;
    }

    .circleSubtitle {
        color: #000000;
    }
}

.yellowContainer {
    h1 {
        color: #FFFFFF;
    }

    .circleTitle {
        color: #FFFFFF;
    }

    .circleSubtitle {
        color: #FFFFFF;
    }

    .sliderLabels {
        color: #FFFFFF;
    }
}