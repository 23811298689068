@import '/src/styles/variables.scss';

.popupWindow {
    position: fixed;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7.5px);
    z-index: 999999999;
    font-family: 'Inter', sans-serif;

    .popupContainer {
        width: 140px;
        height: 140px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 230px;
        left: calc((100vw - 140px) / 2);
        padding: 15px;
        z-index: 6;

        .loadingRing {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }

        .loadingRing div{
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 5px solid #fff;
            border-radius: 50%;
            animation: loadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
        }

        .loadingRing div:nth-child(1) {
            animation-delay: -0.45s;
        }

        .loadingRing div:nth-child(2) {
            animation-delay: -0.3s;
        }

        .loadingRing div:nth-child(3) {
            animation-delay: -0.15s;
        }

        @keyframes loadingRing {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            text-align: center;
            color: #FFFFFF;
            margin: 7px 0;
        }
    }
}