.mainContainer {
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    position: relative;

    .absoluteBackground {
        position: absolute;
        left: 0;
        z-index: -1;

        img {
            width: 100vw;
        }
    }

    .logoImage{
        margin-top: 20px;
    }

    .title {
        margin-top: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        letter-spacing: -0.165px;
        color: #E15768;
    }

    .subtitle {
        margin-top: 15px;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #000000;
    }

    .imageBanner {
        margin-top: 50px;
        padding: 10px;


        img {
            //width: 100%;
            max-height: 325px;
        }
    }

    .pointsBox {
        padding: 0 20px;
        width: 100%;
    }
}

.lightPurpleContainer {
    .logoImage {
        margin-top: 50px;
    }
}

.yellowContainer {
    .subtitle {
        color: #FFFFFF;
    }
}