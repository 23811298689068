.mainContainer {
    width: 100%;
    height: 100%;
    margin: 23px 0 20px;
    text-align: center;

    h1 {
        height: 70px;
    }

    .cardsContainer {
        padding: 0 20px;
        width: 100%;

        .cardsBlock {
            margin-bottom: 10px;
            width: 100%;
        }

        .subtitle {
            display: block;
            text-align: left;
            margin: 10px 10px 10px 20px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #BBBBBB;
        }
    }
}