.mainContainer {
    width: 100%;
    max-width: 420px;
    padding: 15px 20px;
    min-height: 100vh;
    height: auto;
    margin: 0 auto;

    .header {
            display: table;
            width: 100%;
    }

    .totalPriceColumn {
    display: table;
    text-align: end;
    width: 100%;
    }

    .oldPriceRow {
                margin-top: 20px;
                display: table;
                width: 100%;
        }

     .offerRow {
                    margin-top: 20px;
                    display: table;
                    width: 100%;
     }

     .totalRow {
                         margin-top: 20px;
                         margin-bottom: 20px;
                         display: table;
                         width: 100%;
          }

    .headerClose {
        display: table-cell;
        margin-top: 20px;
        width: 17.75px;
        height: 17.75px;
        align-self: flex-start;
        cursor: pointer;
    }


    .headerCheckout {
        display: table-cell;
        font-size: 22px;
        width: 95%;
        text-align: center;
    }

    .closeIcon {
        margin-top: 20px;
        width: 17.75px;
        height: 17.75px;
        align-self: flex-start;
        cursor: pointer;
    }

    .paymentInfoContainer {
            display: table;
            width: 100%;
    }

    .oldPrice {
            display: table-cell;

            font-size: 16px;

            text-align: start;
    }

    .oldPriceValue {
                display: table-cell;

                font-size: 16px;
                font-weight: 600;

                text-align: end;
        }

    .discountPrice {
                display: table-cell;
                font-size: 16px;

                text-align: start;
    }

    .totalPriceCell{
    display: table-cell;
    text-align: end;
    width: 50%;
    }

    .totalPriceValueSave {
                display: table-row;
                font-size: 13px;
                font-weight: 600;
                color: red;
                text-align: end;
    }


    .discountPriceValue {
                    display: table-cell;
                    font-size: 16px;
                   font-weight: 600;
                   color: red;
                   text-align: end;
        }

    .hr {
        margin-top: 25px;
        margin-bottom: 25px;
        border: none;
        height: 1px;
        color: #DADADA;
        background-color: #DADADA;
    }

    .totalPrice {
                    display: table-cell;
                    font-size: 16px;
                    width: 50%;
                    text-align: start;
        }
    .totalPriceValue {
                        display: table-row;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: end;
            }

    .title {
        margin-top: 25px;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        text-transform: uppercase;
    }

    .subtitle {
        margin: 20px 0 28px;
        padding: 0 13px;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: center;

        span {
            text-transform: lowercase;
        }
    }

    .textOr {
        margin: 19px 0 9px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #BBBBBB;
        text-align: center;
    }

    .labelsContainer {
        width: 100%;
        margin-bottom: 15px;

        .imagesContainer {
            justify-content: flex-start;
        
            img {
                margin-right: 5px;
        
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    
        .stripeLabel {
            justify-content: center;
            width: 98px;
            height: 21px;
            border: 1px solid #BBBBBB;
            border-radius: 5px;
    
            p {
                font-weight: 500;
                font-size: 10px;
                line-height: 120%;
                color: #BBBBBB;
                text-align: center;
            }
        }
    }

    .paymentContainer {
        width: 100%;
        margin-top: 39px;

        .active {
            border: 1px solid #E15768 !important;
            box-shadow: 0px 0px 5px #E15768;

            img {
                opacity: 1 !important;
            }

            p {
                opacity: 1 !important;
            }
        }
        
        .paymentMethod {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 103px;
            height: 72px;
            background: #FFFFFF;
            border: 1px solid #C4C4C4;
            border-radius: 8px;
            cursor: pointer;

            img {
                opacity: 0.5;
            }

            .cardsContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 10px;

                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 28px;
                    color: #818181;
                }
            }

            .cards {
                width: 60px;
                height: 33px;
            }

            .payPal {
                width: 76px;
                height: 24px;
            }
        }
    }

    .underline {
        margin: 15px 0 10px;
        width: 100%;
        border: 0.5px solid #F3F4F7;
    }

    .countContainer {
        width: 100%;

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 28px;
            color: #818181;
        }
    }

    .totalContainer {
        margin-top: 5px;
        width: 100%;

        h2 {
            font-weight: 800;
            font-size: 15px;
            line-height: 28px;
            color: #000;
        }

        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 28px;
            color: #818181;
        }

        span {
            margin-right: 7px;
            font-weight: 800;
            font-size: 16px;
            line-height: 28px;
            color: #E15768;
        }
    }

    .description {
        margin: 16px 0 20px;
        font-weight: 500;
        font-size: 9px;
        line-height: 12px;
        color: #BBBBBB;
        text-align: center;
    }

    .applePay {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;

        img {
            width: 18px;
            height: 18px;
        }

        p {
            font-weight: 500;
            font-size: 22px;
            line-height: 28px;
            opacity: 0.5;
        }
    }
}

.yellowContainer {
    background-color: #1F2022;

    .headerCheckout, .title {
        color: #FFFFFF;
    }

    .subtitle {
        color: #FFFFFF;
    }
}