.mainContainer {
    margin-top: 15px;
    width: 132px;
    height: 91px;
    z-index: 1;

    .circleContainer {
        position: relative;
        width: 73px;
        height: 73px;
        border: double 4px transparent;
        border-radius: 50%;
        background-image: linear-gradient(white, white), radial-gradient(circle at center top, #fd6c59, #dd3787);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: inline-block;
        box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3);

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 29px;
            height: 29px;
        }

        &::after {
            content: '';
            position: absolute;
            top: -18px;
            left: -42.5px;
            width: 132px;
            height: 91px;
            z-index: -1;
            background-image: url('../../../assets/images/result/result-background.png');
            background-size: 132px 91px;
        }
    }

    .pinkCircleContainer {
        background-image: linear-gradient(white, white), radial-gradient(circle at center top, #fd6c59,#dd3787);
        box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3);
    }

    .purpleCircleContainer {
        background-image: linear-gradient(white, white), radial-gradient(circle at center top, #7D8BF6, #7D8BF6);
        box-shadow: 0 0 10px -2px rgba(202, 207, 247, 0.79);
    }

    .lightPurpleCircleContainer {
        background-image: linear-gradient(white, white), radial-gradient(circle at center top, #B826F6, #B826F6);
        box-shadow: 0 0 10px -2px rgba(216, 175, 234, 0.79);
    }

    .yellowCircleContainer {
        background-image: linear-gradient(white, white), radial-gradient(circle at center top, #DAFF21, #DAFF21);
        box-shadow: 0 0 10px -2px rgba(216, 175, 234, 0.79);
    }
}