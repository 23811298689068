.mainContainer {
    width: 100%;
    height: 100%;
    margin: 23px 0 20px;
    text-align: center;

    h1 {
        height: 70px;
    }

    .marginTitle {
        margin-bottom: 60px;
    }

    .cardsContainer {
        width: 100%;
        padding: 0 20px;
    }

}