@import '/src/styles/variables.scss';

.mainContainer {
    background-color: #fff;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    .title {
        font-weight: 800;
        font-size: 26px;
        line-height: 26px;
        color: #000000;
        margin-top: 20px;
        margin-bottom: 15px;
        text-align: center;
    }

    .offerContainerActive {
                width: calc(100% - 40px);
                display: flex;
                align-items: flex-start;
                max-height: 60px;
                margin-bottom: 15px;
                margin-left: 15px;
                margin-right: 15px;
                border: 1px solid #E15768;
                box-shadow: $box-shadow-active;
                border-radius: 9px;

                .offerIcon {
                            max-height: 59px;

                            img {
                                max-height: 58px;
                                  max-width: none !important;
                                  border-radius-left: 7px;
                                      min-width: 97px;
                            }
                    }
                }
    .offerContainerActiveWithGift {
                    width: calc(100% - 40px);
                    display: flex;
                    align-items: flex-start;
                    max-height: 80px;
                    margin-bottom: 15px;
                    margin-left: 15px;
                    margin-right: 15px;
                    border: 1px solid #E15768;
                    box-shadow: $box-shadow-active;
                    border-radius: 9px;

                    .offerIcon {
                                max-height: 79px;

                                img {
                                    max-height: 78px;
                                      max-width: none !important;
                                      border-radius-left: 7px;
                                          min-width: 97px;
                                }
                        }
    }

    .offerContainerInactive {
                    width: calc(100% - 40px);
                    display: flex;
                    align-items: flex-start;
                    max-height: 60px;
                    margin-bottom: 15px;
                    margin-left: 15px;
                    margin-right: 15px;
                    box-shadow: $box-shadow;
                    border-radius: 9px;


                    .offerIcon {
                                               // max-height: 58px;

                                                img {
                                                    max-height: 59px;
                                                    max-width: none !important;
                                                    border-radius-left: 7px;
                                                        min-width: 97px;
                                                }
                                        }
    }

     .offerContainerInactiveWithGift {
                        width: calc(100% - 40px);
                        display: flex;
                        align-items: flex-start;
                        max-height: 80px;
                        margin-bottom: 15px;
                        margin-left: 15px;
                        margin-right: 15px;
                        box-shadow: $box-shadow;
                        border-radius: 9px;


                        .offerIcon {
                                                   // max-height: 58px;

                                                    img {
                                                        max-height: 79px;
                                                        max-width: none !important;
                                                        border-radius-left: 7px;
                                                            min-width: 97px;
                                                    }
                                            }
        }

    .contentContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 11px;
            margin-top: 10px;
             font-size: 12px;
             @media screen and (max-width: 404px) {
                 font-size: 12px !important;
             }

            .offerTitle {
                margin-top: 4px;
                text-align: start;
                font-weight: 500;
            }



             .discountContainer {
                            display: flex;
                            align-items: self-end;
                            //margin-left: 21px;

                             .cardSubtitle {
                                            margin-right: 3px;
                                            text-decoration-line: line-through;
                                            text-align: start;
                                            font-weight: 400;
                                            font-size: 12px;
                                            //line-height: 15px;
                                            color: #000000;
                                             @media screen and (max-width: 404px) {
                                                             font-size: 12px !important;
                                                         }
                                        }
                              .cardSubtitleOnly {
                                                                         margin-right: 3px;
                                                                         text-align: start;
                                                                         font-weight: 400;
                                                                         font-size: 12px;
                                                                       //  line-height: 15px;
                                                                         color: #E15768;
                                                                          @media screen and (max-width: 404px) {
                                                                                          font-size: 12px !important;
                                                                                      }
                                                                     }
                               .ceoDiscount {
                                                      margin-left: 10px;
                                                      padding: 2px;
                                                      background: #FFFFFF;
                                                      border: 1px solid #E15768;
                                                      border-radius: 4px;
                                                      font-weight: 600;
                                                      font-size: 10px;
                                                    //  line-height: 12px;
                                                      color: #E15768;
                                                       @media screen and (max-width: 404px) {
                                                                       font-size: 9px !important;
                                                                   }
                                                  }
             }
    }

    .cardIcon {
            min-width: 20px;
            width: 20px;
            height: 20px;

            img {
                width: 20px;
                height: 20px;
            }
        }

    .infoBlock {
      min-height: 200px;
    }

    .infoContainer {
                display: flex;
                align-items: flex-start;
                margin-left: 21px;
                margin-right: 21px;
                margin-bottom: 11px;

                .cardTitle {
                    margin-left: 10px;
                    text-align: start;
                }
        }
    .infoContainerGift {
                    display: flex;
                    align-items: flex-start;
                    margin-left: 21px;
                    margin-right: 21px;
                    margin-bottom: 11px;

                    .cardTitle {
                        margin-left: 10px;
                        text-align: start;
                        font-weight: 600;
                    }
            }

    .cardTitle {
        margin-left: 21px;
        text-align: start;
    }

    .cardSubtitle {
        margin-top: 3px;
        text-align: start;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #BBBBBB;
    }

    .ceoBox {
        margin: 20px 30px;
        background: #7D8BF6;
        border: 2px solid #E25869;
        backdrop-filter: blur(150px);
        border-radius: 10px;

        .ceoImage {
            border-radius: 8px;
        }

        .ceoBoxInfo {
            padding: 15px;
            text-align: start;

            .ceoTitle {
                width: 100%;
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
                color: #FFFFFF;
            }

            .ceoSubtitle {
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #FFFFFF;
            }

            .ceoBonus {
                margin: 15px 0;
                padding: 10px 0;
                background: rgba(88, 89, 91, 0.4);
                backdrop-filter: blur(3.5px);
                border-radius: 8px;
                width: 100%;

                .ceoBonusColumn {
                    width: 100%;
                    justify-content: center;
                    gap: 10px;
                    border-left: solid 1px white;

                    &:first-child {
                        border-left: unset;
                    }
                    .ceoBonusImage {
                        width: 20px;
                        height: 20px;
                    }

                    .ceoBonusInfo {
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                    }
                }
            }

            .ceoPrice {
                width: 100%;

                .ceoPriceContainer {
                    align-items: flex-start;
                    gap: 10px;

                    .ceoPriceTitle {
                        font-weight: 800;
                        font-size: 15px;
                        line-height: 17px;
                        color: #000000;
                        text-transform: uppercase;
                    }

                    .oldPrice {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                        color: #000000;
                        position: relative;

                        span {
                            position: absolute;
                            width: calc(100% + 4px);
                            border: 1px solid #000000;
                            top: 50%;
                            left: 0;
                            transform: translate(-2px, -50%);
                        }
                    }


                }

                .rightContent {
                    align-items: flex-end;

                    .perDayContainer {
                        width: 83px;
                        height: 46px;
                        padding: 3px 8px 3px 19px;
                        background-image: url(../../../assets/images/shot-active-price.svg);
                        color: #000000;

                        .dollar {
                            margin: 4px 3px 0 0;
                            align-self: flex-start;
                            font-weight: 900;
                            font-size: 15px;
                            line-height: 17px;
                        }

                        .beforeDot {
                            font-weight: 800;
                            font-size: 40px;
                            line-height: 17px;
                        }

                        .subtitleContainer {
                            margin-top: 5px;
                            align-items: flex-start;

                            .afterDot {
                                font-weight: 900;
                                font-size: 15px;
                                line-height: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    .buttonContainer {
        position: fixed;
        bottom: 12px;
        left: 20px;
        margin-bottom: 20px;
       // position: relative;
        width: calc(100% - 40px);
        height: 48px;
        padding: 0 24px;
        border: none;
        border-radius: 8px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
        background: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
        box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3) !important;
        cursor: pointer;
        z-index: 14;
    }

    .skipButton {
        margin-bottom: 20px;
        gap: 7px;
        width: 100%;
        justify-content: center;
        cursor: pointer;

        .buttonText {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #BBBBBB;
        }

        .skipIcon {
            width: 8px;
            height: 16px;
        }
    }
}