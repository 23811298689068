.mainContainer {
    width: 100%;
    text-align: center;
    min-width: 375px;
    max-width: 425px;

    h1 {
        color: #000000;
        padding: 0 50px;
    }

    .marginTitle {
        padding: 0 20px;
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #000000;
    }

    .cardContainer {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 15px;
    }

    .chartArea {
        position: relative;
        background-color: #fff;
        width: 100%;
        padding: 0 20px;
        margin: 20px 0;
    }

    .infoBox {
        align-items: flex-start;
        margin: 0 20px;
        padding: 15px;
        background: #F4F4F4;
        border-radius: 15px;

        .backhandImage {
            width: 30px;
            height: 30px;
        }

        .subtitle {
            padding-left: 15px;
            font-style: italic;
            text-align: left;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
        }
    }
}