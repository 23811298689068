.mainContainer {
    width: 100%;
    height: 100%;
    margin: 23px 0 20px;

    .marginTitle {
        margin-bottom: 179px;
    }

    .marginTitleUp {
        margin-bottom: 140px;
    }

    .cardContainer {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 15px;
    }
}

.lightPurpleContainer {
    .marginTitle, .marginTitleUp {
        color: #000000;
    }
}


.yellowContainer {
    h1 {
        color: #FFFFFF;
    }

    .cardContainer > div {
        background-color: #FFFFFF;
    }
}