.mainContainer {
    width: 100%;
    height: calc(100vh - 84px);
    padding: 23px 0;
    text-align: center;

    .marginTitle {
        margin-bottom: 60px;
    }

    .cardContainer {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 15px;

    }



    .cardContainer:last-child {
        margin-bottom: 0;
    }

    .cardsWrapper {
                            width: 100%;
                            margin-top: 20px;
                            padding-left: 20px;
                            padding-right: 20px;
                            text-align: left;
       }

    .cardsContainer {
        width: 100%;
        padding: 0 20px;

        .cardContainer {
            width: 100%;
            padding: 0 10px;
            height: 140px;
            margin-bottom: unset;
        }
    }
}
.multiSelectContainer {
    height: calc(130vh - 84px);
}

.lightPurpleContainer {
    height: auto;

    .marginTitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #000000;
    }
}

.yellowContainer {
    height: auto;

    h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        color: #FFFFFF;
    }

    .marginTitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #FFFFFF;
    }
}
