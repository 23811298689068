.mainContainer {
    height: 100%;
    min-width: 375px;
    max-width: 425px;
    margin: 0 0 95px;
    text-align: center;

    .videoFeedback {
        margin: 0 20px;
    }

    .image {
        width: calc(100% - 40px);
        margin: 0 20px;
        border-radius: 20px;
    }

    .commentContainer {
        margin: 19px 20px 0;
        padding: 20px;
        background: #F4F4F4;
        box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);
        border-radius: 8px;

        .titleContainer {
            h2 {
                text-align: start;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
            }

            img {
                margin: 8px 0 8px -5px;
                width: 89px;
                height: 13.7px;
            }
        }

        p {
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            font-weight: 400;
            text-align: start;
            margin-bottom: 10px;
        }

        .authorContainer {
            span {
                font-weight: 400;
                font-size: 11px;
                line-height: 13px;
            }
        }
    }
}

.lightPurpleContainer {

}
