@import '/src/styles/variables.scss';

.cardContainer {
    width: 100%;
    height: 60px;
    padding: 0 10px;
    margin-bottom: 15px;
    position: relative;
	border: 0.1px solid #d3d3d3;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
	cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    .cardIcon {
        width: 48px;
        height: 48px;
    }

    .cardTitle {
        margin-left: 15px;
    }

    .cardTitleWithSubtext {
        text-align: left;

        .cardText {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
        }

        .cardSubtext {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #BBBBBB;
        }
    }
}

.smallCardContainer {
    width: 140px;
    background-color: #FFFFFF;
    padding: 0 22px;

    .cardIcon {
            width: 25px;
            height: 25px;
        }

    .cardTitle {
        margin-left: 21px;
     }
}

.activeCard {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px; 
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }
}

.pinkActiveCard {
    &::before {
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
    }
}

.purpleActiveCard {
    &::before {
        border: 1px solid #7D8BF6;
        box-shadow: 0 0 10px -2px rgba(202, 207, 247, 0.79);
    }
}

.lightPurpleActiveCard {
    &::before {
        border: 1px solid #B826F6;
        box-shadow: 0 0 10px -2px rgba(216, 175, 234, 0.79);
    }
}

.yellowActiveCard {
    &::before {
        border: 1px solid #DAFF21;
        box-shadow: unset;
    }
}