.galleryImageSale {
    position: relative;

    .imageSaleAd {
        padding: 0 10px;
        width: 100%;
        position: absolute;
        background-color: unset;
        justify-content: space-between;
        gap: 30px;
    }

    .imageSaleAdText {
        width: 100%;
        justify-content: space-around;
    }

    .imageSaleAdTop {
        top: 0;
    }

    .imageSaleAdBottom {
        bottom: 0;
    }

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        color: unset;
    }

    .imageSaleStar {
        width: 10px;
        height: 10px;
    }
}

.bFContainer {
    .imageSaleAd {
        background-color: #000000;
    }

    span {
        color: #FFE001;
    }
}

.nYContainer {
    .imageSaleAd {
        background-color: #2D603F;
    }

    span {
        color: #FFFFFF;
    }

    .imageSaleStar {
        width: 10px;
        height: 13px;
    }
}