.componentContainer {
    width: 100%;
}

.bodyContainer {
    padding-top: 14px;
}

.withBackgroundImageContainer {
    position: relative;

    .bodyContainer {
        padding-top: 14px;
    }
}

.titleContainer {
    position: relative;
    width: calc(100% - 40px);
    padding: 0 20px;
    margin-bottom: 13px;
    justify-content: center;

    .backButton {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    .title {
        margin-top: 1px;
        margin-bottom: 0;
        z-index: 1;
    }
}

.disablePaddings {
    margin: 0 !important;
}

.yellowContainer {
    .titleContainer {
        .title {
            color: #FFFFFF;
        }
    }
}
