.mainContainer {
    height: 100%;
    margin: 23px 0 95px;
    text-align: center;

    .marginTitle {
        padding: 0 88px 85px;
    }

    .ageTitle {
        font-weight: 700;
        font-size: 64px;
        line-height: 77px;
    }

    .ageSubtitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #818181;
        margin-bottom: 37px;
    }

    .pickerContainer {
        width: 100%;
        margin-top: auto;
    }

    .errorMessage {
        margin: 20px 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #E25869;
    }
}

.lightPurpleContainer {
  .marginTitle {
      color: #000000;
  }
}

.yellowContainer {
    h1 {
        color: #FFFFFF;
    }
}
