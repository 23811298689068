.mainContainer {
    background-color: #fff;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    .logoImage{
        margin-top: 20px;
    }

    .contentContainer {
        justify-content: center;

        .imageContainer {
            padding: 90px 110px 50px 110px;
        }
    
        .title {
            font-weight: 800;
            font-size: 23px;
            line-height: 28px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
        }
    
        .subtitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #1B1D22;
            padding: 15px 70px;
        }
    }
}