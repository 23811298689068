@import '/src/styles/variables.scss';

.popupWindow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.42);
    backdrop-filter: blur(7.5px);
    z-index: 5;
    font-family: 'Inter', sans-serif;

    .popupContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 230px;
        left: calc((100vw - 340px) / 2);
        width: 340px;
        padding: 24px;
        background-color: white;
        border-radius: 18px;
        z-index: 6;

        .imageContainer {
            width: 80px;
        }

        p {
            margin: 10px 0;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
            color: #000000;
        }

        .popupButton {
            width: 180px;
            height: 48px;
            padding: 5px 15px;
            margin-top: 16px;
            border: none;
            border-radius: $border-radius;
            color: #fff;
            font-weight: 700;
            font-size: 17px;
            line-height: 16px;
            background: linear-gradient(353deg, rgba(221,55,135,1) 5.23%, rgba(253,108,89,1) 83.78%);
            box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3) !important;
            cursor: pointer;
            z-index: 9;
        }
    }
}