.registrationStepper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 280px;
    margin-top: 15px;
}


.registrationStepper__step {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .titleContainer {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;

        .title {
            padding: 0 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #BBBBBB;
        }

        .smallTitle {
            padding: 0 10px;
        }

        .activeTitle {
            font-weight: 600;
            color: #000000;
        }
    }

    .labelContainer {
        position: relative;
        display: flex;
        width: 100%;
        flex-flow: column nowrap;
        align-items: center;
    }

    .defaultStep {
        position: relative;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        z-index: 4;
    }

    .inactiveStep {
        border: 3px solid #E8E8E8;
        background-color: #ffffff;
    }

    .failedStep {
        background-image: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
        box-shadow: 0 0 2px rgba(225, 87, 104, 0.7);
        display: inline-flex;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-image: url('../../../assets/images/cross.svg');
            background-repeat: no-repeat;
            -webkit-background-size: 12px 12px;
            background-size: 12px 12px;
            content: "";
        }
    }

    .progressStep {
        background-origin: border-box;
        background-clip: content-box, border-box;
        border: double 4px transparent;
        background-image: linear-gradient(white, white), radial-gradient(circle at center top, #fd6c59,#dd3787);
        display: inline-flex;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-image: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            content: "";
        }
    }

    .completedStep {
        background-image: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
        box-shadow: 0 0 2px rgba(225, 87, 104, 0.7);
        display: inline-flex;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-image: url('../../../assets/images/check-icon.svg');
            background-repeat: no-repeat;
            -webkit-background-size: 12px 12px;
            background-size: 12px 12px;
            content: "";
        }
    }

    .linearProgress {
        height: 4px;
        position: absolute !important;
        left: -40px;
        right: 39px;
        top: 51%;
        transform: translate(0, -50%);

        background-color: #fff !important;

        &::after {
            content: '' !important;
            transform: translate(0, -50%);
            width: 100%;
        }
    }
}
