.mainContainer {
    width: 100%;
    height: 100%;
    margin: 23px 0 95px;
    text-align: center;

    .title {
        padding: 0 48px 70px;
    }

    .imageContainer {
        width: 100%;
        padding: 0 20px;
    }

    .checkboxContainer {
        margin: 39px 0 37px;

        span {
            margin-left: 10px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }
    }
}