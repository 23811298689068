.paymentContainer {
    width: 100%;

    .field {
        height: 50px;
        border: 1px solid #BBBBBB;
        font-weight: 500;
        font-size: 13px;
        line-height: 120%;
        color: #FFFFFF;

        border-radius: 8px;
        max-width: 100%;
    }

    .fieldCardName {
        padding-top: 1.2em;
        padding-left: 10px;
        margin-right: 0.3em;
        min-width: 58%;
        background-color: #FFFFFF;
    }

    .fieldMc {
        padding-top: 1.2em;
        padding-left: 5%;
        margin-right: 0.3em;
        min-width: 24%;
        background-color: #FFFFFF;
    }

    .fieldCvc {
        padding-top: 1.2em;
        padding-left: 10px;
        width: 100%;
        background-color: #FFFFFF;
    }

    .errorFormField {
        color: #E4584C;
        font-size: 12px;
        padding-top: 3px;
        padding-left: 4px;
    }

    .errorContainer {
        margin-bottom: 12px;
    }

    .fieldCardHolderName {
        font-weight: 400;
        padding-left: 8px;
        min-width: 100%;
        color: #000;

        &::placeholder {
            text-transform: capitalize;
            color: #BBBBBB;
            font-weight: 500;
            font-size: 13px;
            line-height: 120%;
        }

        &:focus {
            outline: 0;
        }
    }

    .marginFieldHolder {
        text-transform: uppercase;
    }

    .marginEmail {
        margin-top: 15px;
    }

    .payButton {
        position: relative;
        z-index: 3;
        width: 100%;
        margin-top: 10px;
        height: 48px;
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
        color: #FFFFFF;
        text-align: center;
        border: none;
        background: linear-gradient(353deg, rgba(221,55,135,1) 5.23%, rgba(253,108,89,1) 83.78%);
        box-shadow: 0px 4px 20px rgba(225, 87, 104, 0.3) !important;
        border-radius: 8px;
        cursor: pointer;

        .buttonText {
            text-transform: uppercase;
        }

        .buttonImage {
            img {
              display: inline;
              margin-right: 5px;
            }
        }

        .pulseButtonRings {
            border: 1px solid #E15768;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            animation: pulse_1 2s infinite;
            z-index: 1;
        }

        .pulseButtonRings:nth-child(2) {
            animation: pulse 2s 0.7s infinite;
        }

        .pulseButtonRings:nth-child(3) {
            animation: pulse 2s 1.4s infinite;
        }

        @keyframes pulse {
            from {
                opacity: 1;
                transform: scale(1, 1)
            }

            to {
                opacity: 0;
                transform: scale(1.04, 1.4);
            }
        }
    }

    .lightGreenCampaign {
        background: #57A97B;
        border: 2px solid #FFFFFF;
        box-shadow: unset !important;
        width: 100%;

        .pulseButtonRings {
            border: 1px solid #57A97B;
        }
    }

    .disableButton {
        opacity: 0.4;
    }

    .buttonMessage {
        margin-top: 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #818181;
        text-align: center;
    }

    .linksContainer {
        margin: 40px 0 30px;
        padding: 0 7px;

        a {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #BBBBBB;
        }
    }

    $spinner: white;
    $background: red;
    $size: 30px;

    .spinner {
        position: absolute;
        top: calc(50% - #{30px / 2});
        left: calc(50% - #{30px / 2});
        width: 30px;
        height: 30px;
        animation: spinner 0.75s linear infinite;
        z-index: 99999;

        span {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        .spinner-inner-1 {
            background: linear-gradient(to right, rgba($spinner, 0) 0%, rgba($spinner, 0) 50%, $spinner 51%);
        }

        .spinner-inner-2 {
            background: linear-gradient(to top, rgba($background, 0) 0%, $background 100%);
        }

        .spinner-inner-3 {
            $offset: 30px * 0.1;
            top: $offset;
            left: $offset;
            width: $size - ($offset * 2);
            height: $size - ($offset * 2);
            background: $background;
        }
    }

    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .inputCardHolder {
        margin-top: 7px;
    }

     .inputEmail {
            margin-top: 3px;
      }
}

.lightGreenPaymentContainer {
    .marginEmail {
        margin-top: 7px;
    }
}

.yellowContainer {
    .cardClass{
        background-image: unset;
        background-color: #BBBBBB;
    }

    .message {
        color: #FFFFFF;
    }

    .payButton {
        color: #000000;
        background: #DAFF21;
        box-shadow: unset !important;
        border: unset;

        .pulseButtonRings {
            border: 1px solid #DAFF21;
        }
    }
}

.textOr {
    margin: 13px 0 13px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #BBBBBB;
    text-align: center;
}

.buttonsContainer {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.message {
    margin-top: 7px;
    text-align: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
}

 .cardClass{
         background-image: linear-gradient(#367CB4, #17364E);
         border-radius: 10px;
         padding: 10px;
 }

.labelsContainer {
    width: 100%;
    margin-bottom: 15px;

    .imagesContainer {
        justify-content: flex-start;

        img {
            width: 115px;
            height: 15px;
        }
    }

    .stripeLabel {
        justify-content: center;
        width: 98px;
        height: 21px;
        border: 1px solid #BBBBBB;
        border-radius: 5px;

        p {
            font-weight: 500;
            font-size: 10px;
            line-height: 120%;
            color: #BBBBBB;
            text-align: center;
        }
    }
}