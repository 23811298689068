.pickerContainer {
    height: 152px;
    width: 100%;
    padding: 0 25px;
    margin-bottom: 27px;

    .swiper {
        width: auto !important;
        height: 152px !important;
    }

    .swiper-wrapper {
        align-items: center;
    }

    .swiper-slide {
        position: relative;
        width: 100% !important;
        border: none;
        box-shadow: none;
        height: 34px !important;
        text-align: center;
        z-index: 999;

        .slideContainer {
            height: 100%;
            justify-content: center;

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #111117;
                opacity: 0.4;
            }
        }
    }

    .swiper-slide-active {
        height: 34px !important;

        .slideContainer {
            p {
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
                opacity: 1;
            }
        }
    }

    .swiper-slide-prev, .swiper-slide-next {
        height: 34px !important;
        .slideContainer {
            p {
                font-weight: 400;
                font-size: 22px;
                line-height: 27px;
                opacity: 1;
            }
        }
    }

    .swiper-slide-prev {
        .slideContainer {
            p {
                align-self: flex-start;
            }
        }
    }

    .swiper-slide-next {
        .slideContainer {
            p {
                align-self: flex-end;
            }
        }
    }

    .activeLine {
        position: absolute;
        width: 100%;
        background: rgba(225, 87, 104, 0.2);
        border-radius: 8px;
        height: 44px;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        z-index: -1;
    }
}

.multipickerContainer {
    height: 152px;
    margin-bottom: 27px;

    .swiper {
        width: 162.5px !important;
        height: 152px !important;
    }

    .right {
        padding: 0 101px 0 35px;
    }

    .left {
        padding: 0 35px 0 101px;
    }

    .swiper-slide {
        position: relative;
        width: 100% !important;
        border: none;
        box-shadow: none;
        height: 34px !important;
        text-align: center;
        z-index: 999;

        .slideContainer {
            height: 100%;
            justify-content: center;
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #111117;
                opacity: 0.4;
            }
        }
    }

    .swiper-slide-active {
        height: 34px !important;

        .slideContainer {
            p {
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
                opacity: 1;
            }
        }
    }

    .swiper-slide-prev, .swiper-slide-next {
        height: 34px !important;
        .slideContainer {
            p {
                font-weight: 400;
                font-size: 22px;
                line-height: 27px;
                opacity: 1;
            }
        }
    }

    .swiper-slide-prev {
        .slideContainer {
            p {
                align-self: flex-start;
            }
        }
    }

    .swiper-slide-next {
        .slideContainer {
            p {
                align-self: flex-end;
            }
        }
    }

    .disable {
        display: none;
    }
}

// @media screen and (min-width: 3) {
//     .swiper {
// 		height: 700px;
// 	}
// }