.mainContainer {
    width: 100%;
    margin: 23px 0;
    text-align: center;

    h1 {
        padding: 0 20px;
    }

    .marginTitle {
        padding: 0 43px;
        margin-bottom: 169px;
    }

    .cardContainer {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 15px;
    }
}

.lightPurpleContainer {
    .marginTitle {
        color: #000000;
    }
}