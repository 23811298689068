.absoluteBackground {
    margin-top: -14px;
    position: absolute;
    height: 100vh;
    left: 0;
    z-index: -1;

    img {
        height: 100vh;
    }
}

.mainContainer {
    height: 100%;
    margin: 23px 0 60px;
    text-align: center;

    .cardTitle {
        padding: 0 80px;
    }

    .marginTitle {
        max-width: 400px;
        padding: 0 75px;
        margin-bottom: 10px;
    }

    .cardsContainer {
        position: relative;

        .cardsWrapper {
            width: 100%;
            margin-top: 70px;
            margin-bottom: 35px;
            margin-right: 40px;
            align-items: end;
        }

        .cardsImage {
            left: 0;
            padding: 10px;
            width: 82%;
            position: absolute;
            display: flex;
            justify-content: center;

            .image {
                width: 90%;
            }
        }
    }
}

.lightPurpleContainer {
    height: auto;

    .marginTitle {
        color: #000000;
    }
}

.yellowContainer {
    height: auto;

    h1 {
        color: #FFFFFF;
    }

    .marginTitle {
        color: #BBBBBB;
    }
}
