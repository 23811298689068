.pageContainer {
    background-color: #FFFFFF;
}

.mainContainer {
    width: 100%;
    text-align: center;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    position: relative;

    .absoluteContainer {
        position: absolute;
    }

    .whiteImage {
        width: 120px;
        margin: 40px auto 0 auto;
    }

    .prefixBox {
        margin-top: 220px;
        gap: 5px;

        .prefixImage {
            width: 30px;
            height: 33px;
        }

        .prefix {
            margin-top: 10px;
            font-weight: 700;
            font-size: 17px;
            line-height: 18px;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 0 0 12px rgba(0,0,0,.74);
        }
    }

    h1 {
        padding: 0 10px;
        margin: 30px auto;
        font-weight: 700;
        font-size: 28px;
        line-height: 31px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0 0 12px rgba(0,0,0,.74);

        @media screen and (max-width: 404px) {
                         font-size: 26px !important;
                     }
    }

    .subtitle {
        padding: 0 30px;
        margin: 10px auto;
        font-weight: 400;
        font-size: 17px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0 0 12px rgba(0,0,0,.74);
    }

    .arrowDownImage {
        width: 25px;
    }

    .cardsContainer {
        padding: 0 15px;
        width: 100%;
        margin: 30px auto;
        gap: 15px;
    }

    .cardContainer {
        width: 100%;
        padding: 0 10px;
    }

    .loadingContainer {
        margin: 30px auto;
        width: 100%;
        padding: 0 30px;

        .subtitle {
            font-size: 12px;
            line-height: 12px;
            gap: 5px;
        }
    }

    .agreements {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0 0 12px rgba(0,0,0,.74);

        .agreementInfo {
            margin: 0 5px;
        }

        .agreementLinks {
            margin: 5px auto;

            a {
                color: #FFFFFF;
                text-decoration-line: underline !important;
            }
        }
    }
}

.yellowPageContainer {
    .prefixBox {
        background: #1F2022;
        border-radius: 10px;
        padding: 5px 16px;

        .prefix {
            margin-top: 0;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #DAFF21;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
    }

    h1 {
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
    }

    .agreements {
        color: #000000;
        text-shadow: unset;
        //text-shadow: 0 0 20px rgba(255,255,255,1);

        .agreementLinks {
            a {
                color: #000000;
            }
        }
    }
}
