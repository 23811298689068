.mainContainer {
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
    text-align: center;
    position: relative;

    .imageBox{
        margin-top: 20px;
        max-height: 15px;
        width: 100%;

        .logoImage {
            margin-left: auto;
            margin-right: auto;
            height: 15px;
        }
    }

    .title {
        margin-top: 40px;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        text-align: left;
        color: #000000;
    }

    .infoBox {
        margin: 30px 0;
        padding: 2px;
        background: rgba(212, 217, 252, 0.31);
        border-radius: 10px;

        .chartArea {
            position: relative;
            width: 100%;
            margin-top: 40px;
        }

        .subtitle {
            padding: 20px 40px;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            text-align: center;
            color: #BBBBBB;
        }
    }

    .remark {
        padding: 0 5px;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #818181;

        b {
            color: #000000;
        }
    }
}

.lightPurpleContainer {
    .infoBox {
        background: #D4D9FC;

        .subtitle {
            color: #000000;
        }
    }

    .remark {
        color: #000000;

        b {
            color: #B826F6;
        }
    }
}

.yellowContainer {
    .title {
        color: #FFFFFF;
    }

    .infoBox {
        background: #575966;

        .subtitle {
            color: #BBBBBB;
        }
    }

    .remark {
        color: #818181;

        b {
            color: #FFFFFF;
        }
    }
}
