.mainContainer {
    background-color: #fff;
    padding: 62px 20px 14px;
    min-width: 375px;
	max-width: 425px;
    height: 100%;
    margin: 0 auto;
    text-align: center;

    h1 {
        line-height: 31px;

        span {
            color: #E15768;
        }
    }

    .emailInput {
        width: 100%;
        margin-top: 35px;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
        padding: 15px 24px;

        &::placeholder {
            color: #BBBBBB;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
        }
    }

    .description {
        margin-top: 35px;
        display: flex;

        img {
            width: 18px;
            height: 20px;
            margin-right: 13px;
        }

        p {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #BBBBBB;
            margin-right: 18px;
            text-align: start;
        }
    }

    .error {
        border-color:#E15768;
    }

    .errorText {
        margin-top: 3px;
        color: #E15768;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        align-self: baseline;
    }
}