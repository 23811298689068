@import '/src/styles/variables.scss';

.cardContainer {
    width: 100%;
    min-height: 60px;
    padding: 0 22px;
    margin-bottom: 15px;
    position: relative;

    .recommendedText {
        top: 5px;
        right: 40px;
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
    }

    .pinkRecommendedText {
        color: #E15768;
    }

    .purpleRecommendedText {
        color: #7D8BF6;
    }

    .lightPurpleRecommendedText {
        color: #B826F6;
    }

    .yellowRecommendedText {
        color: #000000;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .cardIcon {
        min-width: 25px;
        width: 25px;
        height: 25px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    .cardTitle {
        margin-left: 21px;
        text-align: start;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 21px;

        .cardTitle {
            margin-left: 0;
            text-align: start;
        }

        .cardSubtitle {
            margin-top: 3px;
            text-align: start;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #BBBBBB;
        }
    }
}

.lightPurpleCardContainer {
    background-color: white;
}

.yellowCardContainer {
    background-color: white;
}

.cardContainerBigImage {
    padding: 7px;

    .cardIcon {
        width: 75px;
        height: 75px;
        min-width: 75px;

        img {
            width: 75px;
            height: 75px;
        }
    }

    .contentContainer {
        margin-left: 10px;
    }
}

.activeCard {
    &::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px; 
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }

    &:active, &:focus-visible, &:focus {
        -moz-appearance:none;
        outline: 0 none transparent;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.pinkActiveCard {
    &::before {
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
    }
}

.purpleActiveCard {
    &::before {
        border: 1px solid #7D8BF6;
        box-shadow: 0 0 10px -2px rgba(202, 207, 247, 0.79);
    }
}

.lightPurpleActiveCard {
    &::before {
        border: 1px solid #B826F6;
        box-shadow: 0 0 10px -2px rgba(216, 175, 234, 0.79);
    }
}

.yellowActiveCard {
    &::before {
        border: 1px solid #DAFF21;
        box-shadow: unset;
    }
}

.cardMessage {
    width: 100%;
    text-align: start;
    padding: 0 75px 0 59px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #BBBBBB;
}

.lightPurpleCardMessage {
    color: #000000;
}
