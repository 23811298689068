@import '/src/styles/variables.scss';

.cardContainer {
    position: relative;
    background-color: #F2F3FE;
    min-width: 160px;
    max-width: 280px;
    height: 100%;
    cursor: pointer;
    border: 0.1px solid #d3d3d3;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    padding: 1px;
    margin: 0 auto;

    .checkboxArea {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 22px;
        top: 22px;
        background-color: unset;

        span {
            box-shadow: unset;
        }
    }

    .imageContainer {
        .cardIcon {
            width: 100%;
            height: 100%;

            img {
                border-radius: 9px;
            }
        }
    }

    .cardInfo {
        width: 100%;
        align-items: start;

        .mainInfo {
            width: 100%;
            padding: 10px;
            align-items: start;
            border-bottom: 2px dashed #818181;

            .liveImage {
                width: 52px;
                height: 20px;

                .liveIcon img {
                    width: 100%;
                    height: 100%;
                }
            }

            .mainInfoText {
                width: 100%;
                margin-top: 7px;
                padding: 0 2px;
                justify-content: space-between;
                text-align: start;

                .timeInfo {
                    width: 100%;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    color: #000000;
                }

                .title {
                    width: 100%;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: #000000;
                }

                .teacher {
                    width: 100%;
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 16px;
                    color: #818181;
                }
            }
        }

        .priceContainer {
            width: 100%;
            background-color: #FFFFFF;
            padding: 14px;
            align-items: start;
            border-radius: 0 0 9px 9px;

            .duration {
                font-weight: 300;
                font-size: 15px;
                line-height: 18px;
                color: #000000;
            }

            .priceInfo {
                margin-top: 10px;
                gap: 5px;

                .oldPrice {
                    position: relative;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 17px;
                    color: #000000;

                    span {
                        position: absolute;
                        width: calc(100% + 4px);
                        border: 0.5px solid #000000;
                        top: 50%;
                        left: 0;
                        transform: translate(-2px, -50%);
                    }
                }

                .newPrice {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 17px;
                    color: #E15768;

                    .priceComment {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.activeCard {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px;
        border: 2px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }
}

.smallCard {
    border: unset;
    padding: 0 1px;
    max-width: 163px;

    .checkboxArea {
        width: 20px;
        height: 20px;
        right: 16px;
        top: 16px;
    }

    .cardInfo {
        .mainInfo {
            padding: 5px;

            .liveImage {
                width: 20px;
                height: 9px;
            }

            .mainInfoText {
                margin-top: 3px;
                padding: 0;

                .timeInfo {
                    font-size: 10px;
                    line-height: 12px;
                }

                .title {
                    font-size: 13px;
                    line-height: 18px;
                }

                .teacher {
                    font-size: 8px;
                    line-height: 10px;
                }
            }
        }

        .priceContainer {
            padding: 5px 5px 10px 5px;

            .duration {
                font-size: 10px;
                line-height: 12px;
            }

            .priceInfo {
                margin-top: 1px;

                .oldPrice {
                    font-size: 12px;

                    span {
                        position: absolute;
                        width: calc(100% + 4px);
                        border: 0.5px solid #000000;
                        top: 50%;
                        left: 0;
                        transform: translate(-2px, -50%);
                    }
                }

                .newPrice {
                    font-weight: 600;
                    font-size: 12px;

                    .priceComment {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
