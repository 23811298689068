@import '/src/styles/variables';

.mainContainer {
    background: #F25570;
    border-radius: 8px;
    width: 100%;
    padding: 2px;
    position: relative;

    .boostBox {
        align-items: start;
        background: #FFFFFF;
        border-radius: 8px;
        width: 100%;
        padding: 10px;
        gap: 15px;

        .rocketImageBox {
            width: 100%;
            max-height: 25px;
            max-width: 25px;

            .rocketImage {
                height: 100%;
                width: 100%;
            }
        }

        .infoBox {
            align-items: start;
            text-align: left;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #000000;

            span {
                margin-top: 5px;
                font-weight: 400;
                line-height: 17px;
            }
        }

        .pricesBox {
            margin: 10px 0;
            gap: 10px;

            .newPrice {
                font-weight: 600;
                font-size: 23px;
                line-height: 17px;
                color: #000000;
            }

            .oldPrice {
                position: relative;
                font-size: 14px;
                color: #818181;

                span {
                    position: absolute;
                    width: calc(100% + 4px);
                    border: 0.5px solid #8F8E94;
                    top: 50%;
                    left: 0;
                    transform: translate(-2px, -50%);
                }
            }
        }
    }

    .timerBox {
        padding: 10px 5px;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        gap: 5px;
    }

    .stickyBox {
        position: fixed;
        border-radius: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background: rgba(242, 85, 112, 0.7);
    }
}

.lightPurpleContainer {
    background: #000000;

    .boostBox {
        background: #D4D9FC;
    }
}

.lightBlackFridayContainer {
    background: #F81F20;

     .boostBox {
                .pricesBox {
                   .oldPrice {
                      span {
                      border: 0.5px solid #F81F20;
                      }
                    }
                }
            }
}

.yellowContainer {
    background: #F81F20;

    .boostBox {
        background: #1F2022;

        .infoBox {
            color: #FFFFFF;
        }

        .pricesBox {
            .newPrice {
                color: #FFFFFF;
            }

            .oldPrice {
                color: #BBBBBB;

                span {
                    border: 0.5px solid #FFFFFF;
                }
            }
        }
    }

}