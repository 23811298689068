@import '/src/styles/variables.scss';

.mainContainer {
    background: #E6E6E6;
    box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);
    border-radius: 8px;
    margin-bottom: 20px;


    .headerBox {
        padding: 20px 20px 0 20px;

        .imageBox {
            width: 60px;
            height: 80px;
            object-fit: cover;
        }

        .leftOliveImageBox {
            .imageBox {
                object-position: 30%;
            }
        }

        .rightOliveImageBox {
            .imageBox {
                object-position: 70%;
            }
        }

        .header {
            .title {
                font-weight: 800;
                font-size: 22px;
                line-height: 27px;
                text-align: center;
                color: #000000;
            }
            .subtitle {
                margin-top: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .downloadLinksBox {
        margin: 25px 35px 35px 35px;
        padding: 15px;
        background: #FFFFFF;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        .imageBox {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .ratingImageBox {
            max-height: 57px;
            max-width: 94px;
        }

        .appStoreImageBox {
            max-height: 57px;
            max-width: 50px;
        }

        .googlePlayImageBox {
            max-height: 55px;
            max-width: 57px;
            margin-top: 2px;
        }
    }
}

.lightPurpleContainer {
    background: #D4D9FC;
}

.yellowContainer {
    background: #D4D9FC;
}