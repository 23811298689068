.mainContainer {
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px 0 20px;
    min-width: 375px;
    max-width: 425px;

    .creatingPlanContainer {
        width: 100%;
        padding: 0 20px;

        .loadingContainer {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #000000;
            border-radius: 10px;
            padding: 10px;
            gap: 10px;
            justify-content: center;
            align-items: center;

            .circleContainer {
                position: relative;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                background: #F2F3FE;
                box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1) !important;

                .circlePercentage {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    padding: 0;
                    margin: 0;
                    transform: translate(-50%, -50%);
                    width: 27px;
                    background-color: #000000;
                    text-shadow: 0 0 #00000000;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 120%;
                    color: #000000;
                }

                svg {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    transform: rotate(-90deg) translate(1px, -1px);

                    circle {
                        width: 100%;
                        height: 100%;
                        fill: none;
                        stroke-width: 2;
                        stroke-linecap: round;
                        transform: translate(1px, 1px);
                        stroke-dasharray: 118;
                        transition: all 0.06s;
                    }
                }
            }

            .subtitle {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #1B1D22;
            }
        }
    }

    .title {
        margin-top: 20px;
        font-weight: 700;
        text-align: center;
        font-size: 26px;
        color: #1B1D22;
        line-height: 31px;

        span {
            color: #7D8BF6;
        }
    }

    .swiperArea {
        margin: 20px 0;
        width: 100%;
    }

    .descriptionArea {
        width: 100%;

        .title {
            margin-top: 0;
            display: none;
        }

        .active {
            display: block;
        }
    }
}

.lightPurpleContainer {
    .title {
        span {
            color: #B826F6;
        }
    }
}

.yellowContainer {
    --swiper-pagination-color: #818181;

    .creatingPlanContainer {
        .loadingContainer {
            .circleContainer {
                background: #F7FFCF;
            }
        }
    }

    .title {
        color: #FFFFFF;

        span {
            color: #DAFF21;
        }
    }
}
