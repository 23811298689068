.mainContainer {
    width: 100%;
    max-width: 460px;
    height: 100vh;
    background-image: url('../../../assets/images/questions/question_1-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-end;
    margin: 0 auto;
    
    .contentContainer {
        position: absolute;
    }

    .buttonsContainer {
        width: 100%;
    }
}