.mainContainer {
    width: 100%;
    margin: 23px 0;
    text-align: center;

    .title {
        padding: 0 25px 254px;
    }

    .cardContainer {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 15px;
    }
}