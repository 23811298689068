@import '/src/styles/variables.scss';

.buttonContainer {
    position: fixed;
    bottom: 12px;
    left: auto;
    max-width: 395px;
    width: calc(100% - 40px);
    height: 48px;
    padding: 0 24px;
    border: none;
    border-radius: $border-radius;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    line-height: 16px;
    background: linear-gradient(353deg, rgba(221,55,135,1) 5.23%, rgba(253,108,89,1) 83.78%);
    box-shadow: 0px 4px 20px rgba(225, 87, 104, 0.3) !important;
    cursor: pointer;
    z-index: 999999999;

    img {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translate(-50%, -50%);
    }
}

.disabledButton {
    background: #FDDEE1;
}