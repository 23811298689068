.cardContainer {
    width: 275px;
    height: auto;
    font-family: 'Roboto', sans-serif;

    .imagesContainer {
        width: 229px;
        height: 258px;
        padding: 18px 23px;
        background: #FFF3F3;
        border-radius: 8px;

        .imageContainer {
            width: 97px;
            height: 222px;
            border: 6px solid #FFFFFF;
            background: #fff;
            border-radius: 8px;

            img {
                width: 100%;
                height: 167px;
                border-radius: 8px;
            }

            p {
                margin-top: 9px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }

            .afterText {
                font-weight: 700;
                color: #E15768;
            }
        }
    }   

    .dataContainer {
        margin: 19px 0;

        p {
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
        }

        .weightData {
            color: #E15768;
        }
    }

    .feedback {
        text-align: justify;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        opacity: 0.4;
    }
}
