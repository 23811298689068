.mainContainer {
    width: 100%;
    height: 100%;
    margin: 23px 0 20px;
    text-align: center;

    .title {
        font-weight: 700;
        font-size: 26px;
        margin: 15px;
        line-height: 31px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        gap: 6px;
    }

    .bellImage {
        width: 25px;
        height: 25px;
    }

    .commentArea {
        background: #F4F8FB;
        border: 1px solid #E7E7E7;
        border-radius: 15px;
        align-items: flex-start;
        margin: 0 20px;
        gap: 10px;
        padding: 10px;

        .image {
            width: 50px;
            min-width: 50px;
            height: 50px;

            .img {
                width: 50px;
                height: 50px;
            }
        }

        .subtitle {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            text-align: left;
            color: #1B1D22;
        }
    }

    .swiperArea {
        margin: 30px 0;
        padding: 20px;

        .commentContainer {
            width: 100%;
            align-content: space-between;
            gap: 20px;

            .commentImage {
                width: 120px;
                height: 120px;
            }

            .text {
                padding: 0 15px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #1B1D22;
            }
        }
    }

    .note {
        padding: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #818181;
        margin-bottom: 35px;
    }
}