.mainContainer {
    height: 100%;
    margin: 23px 0 95px;
    text-align: center;
    width: 100%;

    .cardTitle {
        padding: 0 60px;
        margin-bottom: 38px;
    }

    .subtitle {
        margin: 31px 0 47px;
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
    }

    h1 {
        span {
            color: #5CD3C6;
        }
    }

    .bodySlider {
        padding: 0 20px;
        width: 100%;
    }
}

.sliderLabels {
    width: 100%;
    padding: 0 20px;
    margin: 11px 0 0;

    span {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
    }

    .rightSpace {
        margin-right: 10px;
    }
}

.lightPurpleContainer {
    .bodySlider {
        padding: 0;
    }
}

.yellowContainer {
    .cardTitle {
        color: #FFFFFF;
    }

    .sliderLabels {
        span {
            color: #FFFFFF;
        }
    }

    .bodySlider {
        padding: 0;
    }
}