.mainContainer {
    margin-bottom: 20px;

    .specialTitleBox {
        .specialPreview {
            font-weight: 200;
            font-size: 12px;
            line-height: 15px;
            color: #8F8E94;
        }

        .specialTitle {
            font-weight: 800;
            font-size: 35px;
            line-height: 42px;
            color: #000000;
        }

        .specialUpText {
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
        }
    }
}

.yellowContainer {
    .specialTitleBox {
        .specialPreview {
            color: #FFFFFF;
        }

        .specialTitle {
            color: #F81F20;
        }

        .specialUpText {
            color: #FFFFFF;
        }
    }
}

.lightBlackFridayContainer {
   .specialTitleBox {
        .specialPreview {
            color: #000000;
        }

        .specialTitle {
            color: #F81F20;
        }

        .specialUpText {
            color: #000000;
        }
    }
}
