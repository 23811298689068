@import '/src/styles/variables';

.timerContainer {
    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #818181;
    }

    span {
        font-weight: 900;
        font-size: 18px;
        line-height: 110%;
        color: #E15768;
    }
}

.whiteTime {
    span {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        width: 65px;
        text-align: start;
        color: #FFFFFF;
    }
}