.absoluteImage {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: -1;

    img {
        width: 100%;
        max-width: 190px;
    }
}

.mainContainer {
    height: 100%;
    margin: 0 0 95px;
    min-width: 375px;
    max-width: 425px;

    .text {
        margin: 15px 0 0;
    }

    .mainBox {
        margin-top: 40px;

        h1 {
            margin-top: 20px;
            font-weight: 700;
            font-size: 26px;
            line-height: 31px;
            color: #000000;

            @media screen and (max-width: 391px) {
                font-size: 24px !important;
               }
        }


        .image {
            height: 275px;
            z-index: 1;

            img {
                border-radius: 20px;
                height: 275px;
            }
        }

        .smallImage {
            height: 200px;

            img {
                height: 200px;
            }
        }

        .text {
            padding: 0 15px;
            font-weight: 400;
            font-size: 17px;
            line-height: 21px;
            color: #000000;

             @media screen and (max-width: 391px) {
                            font-size: 15px !important;
                }
        }

        .multiColorText {
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;

            .text {
                margin-top: 5px;
                padding: unset;
                text-align: center;
                justify-content: space-between;
                width: 100%;
            }

            .lightPurple {
                font-weight: 700;
                color: #B826F6;
            }

            .red {
                font-weight: 700;
                color: #E15768;
            }

            .blue {
                font-weight: 700;
                color: #7D8BF6;
            }

            .yellow {
                font-weight: 700;
                color: #DAFF21;
            }

            .boldText {
                margin-top: 15px;
                font-weight: 700;
                font-size: 17px;
                line-height: 21px;
                text-align: center;
                color: #000000;

                @media screen and (max-width: 391px) {
                       font-size: 15px !important;
                }
            }
        }
    }
}

.yellowContainer {
    .mainBox {
        h1 {
            color: #FFFFFF;
        }

        .text {
            color: #FFFFFF;
        }

        .multiColorText {
            .boldText {
                color: #FFFFFF;
            }
        }
    }
}