@import '/src/styles/variables';

.mainContainer{
    width: 100%;
    height: 100%;

    .imageContainer {
        width: 100%;
        margin: -20px;
        position: absolute;
    }
}
