@import '/src/styles/variables';

.mainContainer {
    padding-top: 20px;

    .title {
        padding: 0 10px;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #000000;

        span {
            color: #E15768;
        }
    }

    .swiperArea {
        margin: 15px 0 5px 0;
        width: 100%;
    }
}

.lightPurpleContainer {
    .title {
        span {
            color: #B826F6;
        }
    }
}

.yellowContainer {
    .title {
        color: #FFFFFF;

        span {
            color: #DAFF21;
        }
    }
}
