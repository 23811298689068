.mainContainer {
    width: 100%;
    margin: 23px 0;
    text-align: center;

    h1 {
        padding: 0 20px;
    }

    .commentArea {
        position: relative;
        margin-top: 120px;

        .absoluteImageBox {
            position: absolute;
            box-sizing: border-box;
            border-radius: 100px;
            width: 95px;
            height: 95px;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                border-radius: 50%;
            }
        }

        .commentBox {
            margin: 0 20px;
            padding: 70px 10px 20px 10px;
            background: #F2F3FE;
            box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);
            border-radius: 8px;

            .comment {
                font-weight: 400;
                font-size: 17px;
                line-height: 21px;
                text-align: center;
                color: #1B1D22;
            }

            .subtextBox {
                gap: 5px;
                justify-content: center;
                margin-top: 40px;

                .subtextImage {
                    width: 20px;
                    height: 20px;
                }

                .subtext {
                    justify-content: center;
                    gap: 5px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 21px;
                    text-align: center;
                    color: #1B1D22;
                }
            }
        }
    }
}

.lightPurpleContainer {
    .commentArea {
        .absoluteImageBox {
            img {
                border: 2px solid #B826F6;
            }
        }
    }
}

.yellowContainer {
    h1 {
        color: #FFFFFF;
    }

    .commentArea {
        .absoluteImageBox {
            img {
                border: 2px solid #DAFF21;
            }
        }
    }
}
