.mainContainer {
    background-color: #fff;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding: 0 20px;

    .title {
        padding: 0 2px;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #000000;
        margin-top: 20px;
        text-align: center;
    }

    .subtitleBox {
        margin-top: 10px;

        .subtitle {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: #777676;
            margin-right: 5px;
        }

        .subtitleBold {
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: #000000;
        }
    }

    .formContainer {
        width: 100%;
        padding: 20px 0;
        margin: 0 auto;

        .cardsContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 9px;
        }
    }

    .buttonContainer {
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
        width: 100%;
        height: 48px;
        padding: 0 24px;
        border: none;
        border-radius: 8px;
        color: #fff;
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
        background: linear-gradient(353deg, rgba(221,55,135,1) 5.23%, rgba(253,108,89,1) 83.78%);
        box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3) !important;
        cursor: pointer;
        z-index: 1;
    }

    .disabledButton {
        background: #FDDEE1;
    }
}