@import '/src/styles/variables.scss';

.mainContainer {
    width: 100%;
    height: 100%;
    min-width: 375px;
    max-width: 425px;
    margin: 23px 0 20px;

    .title{
        padding: 0 30px;
    }

    .marginTitle {
        padding: 0 40px;
        margin-bottom: 25px;
    }

    .cardsContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 15px;
        grid-column-gap: 21px;
        padding: 0 20px 17px;

        .cardWrapper {
            width: 100%;
            height: 50px;
            justify-content: center;

            .cardContent {
                color: #000000;
            }
        }
    }

    .activeCard {
        &::before {
            content: "";
            position: absolute;
            top: -0.4px;
            right: -0.4px;
            bottom: -0.4px;
            left: -0.4px; 
            border: 1px solid #E15768;
            box-shadow: $box-shadow-active;
            border-radius: $border-radius;
        }
    }

    .pinkActiveCard {
        &::before {
            border: 1px solid #E15768;
            box-shadow: $box-shadow-active;
        }
    }

    .purpleActiveCard {
        &::before {
            border: 1px solid #7D8BF6;
            box-shadow: 0 0 10px -2px rgba(202, 207, 247, 0.79);
        }
    }

    .button {
        border: none;
        background: transparent;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #BBBBBB;
        cursor: pointer;
    }

    .activeButton {
        color: #E15768;
    }
}