.ageGroupContainer {
    background-color: #fff;
    padding: 14px 20px;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;

    .starsContainer {
        margin-top: 15px;
        align-items: center;

        .starsImage {
            margin-right: 4px;
        }

        .rating {
            font-size: 15px;
            line-height: 28px;
            font-weight: 800;
        }
    }

    .downloadsText {
        margin-top: 19px;
        padding: 0 25px;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        text-transform: uppercase;
        text-align: center;

        .boldText {
            font-size: 17px;
            font-weight: 700;
            line-height: 21px;
        }
    }

    .downloadsTextA {
            margin-top: 17px;
            margin-bottom: 17px;
            padding: 0 25px;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            //text-transform: uppercase;
            text-align: center;

            .boldText {
                font-size: 17px;
                font-weight: 700;
                line-height: 21px;
            }
        }

    .subtitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin: 18px 0 12px;
        text-transform: uppercase;
    }

    .subtitleA {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            margin: 18px 0 0px;
            //text-transform: uppercase;
        }

    .gridContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 21px;
        grid-row-gap: 18px;

    }
}