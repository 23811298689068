.switchContainer {
    position: relative;
    width: 152px;
    height: 40px;
    padding: 4px 15px;
    border-radius: 14px;
    background: #FFF8F7;
    box-shadow: inset 0 1px 2px rgba(97, 97, 97, 0.2), inset 0 2px 4px rgba(97, 97, 97, 0.2);

    button {
        position: relative;
        width: 55px;
        height: 32px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #E15768;
        opacity: 0.5;
        padding: 5px 12px;
        background: none;
        border-radius: 50px;
        border: 0;
        outline: none;
        transition: .3s;
        text-align: center;
        cursor: pointer;

        &:hover,
        &:focus,
        &.isActive {
            width: 55px;
            height: 32px;
            box-shadow: 0 1px 2px rgba(218, 16, 11, 0.2), 0 2px 4px rgba(218, 16, 11, 0.2) !important;
            color: #000000;
            opacity: 1;
            border-radius: 12px;
            text-align: center;
            background: #FFFFFF;
        }
    }

}

.yellowContainer {
    background: #F7FFCF;

    button {
        color: #8EAA00;

        &:hover,
        &:focus,
        &.isActive {
            box-shadow: 0 1px 2px rgba(143, 171, 0, 0.4), 0 2px 4px rgba(143, 171, 0, 0.4) !important;
            color: #000000;
        }
    }
}
