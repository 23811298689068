.StripeElement {
    margin-bottom: 4px;
    border-radius: 8px;
    width: 180px !important;

     @media screen and (max-width: 404px) {
         width: 175px !important;
     }
      @media screen and (max-width: 395px) {
              width: 170px !important;
     }
      @media screen and (max-width: 385px) {
                   width: 165px !important;
      }
       @media screen and (max-width: 375px) {
                         width: 160px !important;
     }
         @media screen and (max-width: 365px) {
                              width: 155px !important;
          }
      @media screen and (max-width: 360px) {
                                   width: 150px !important;
               }

}

.paypal-buttons {
    height: 48px;
    width: 180px !important;
    @media screen and (max-width: 404px) {
       width: 175px !important;
    }
    @media screen and (max-width: 395px) {
           width: 170px !important;
    }
      @media screen and (max-width: 385px) {
               width: 165px !important;
        }
       @media screen and (max-width: 375px) {
                   width: 160px !important;
            }
     @media screen and (max-width: 365px) {
                       width: 155px !important;
                }
    @media screen and (max-width: 360px) {
                           width: 150px !important;
                    }
}