.mainContainer {
    --swiper-pagination-color: #818181;
    --swiper-pagination-bullet-size: 6px;
    height: 335px;

    .swiperSlide {
        max-height: 300px;
    }

    .swiperCard {
        position: relative;
        max-height: 300px;
        height: 100%;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        overflow: hidden;

        .swiperChart {
            width: 100%;

            .chartImage {
                .image {
                    width: 100%;
                    height: 300px;
                }
            }

            .chartText {
                position: absolute;
                width: 50px;
                height: 15px;
                font-weight: 600;
                font-size: 11px;
                line-height: 14px;
            }

            .chartWeightTarget {
                color: #FFFFFF;
                bottom: 36%;
                right: 5%;

                @media screen and (min-width: 385px) {
                    bottom: 35%;
                }
                @media screen and (min-width: 390px) {
                    bottom: 34%;
                }
                @media screen and (min-width: 395px) {
                    bottom: 33%;
                }
                @media screen and (min-width: 400px) {
                    bottom: 32%;
                }
                @media screen and (min-width: 405px) {
                    bottom: 31%;
                }
                @media screen and (min-width: 410px) {
                    bottom: 30%;
                }
                @media screen and (min-width: 420px) {
                    bottom: 29%;
                }
                @media screen and (min-width: 425px) {
                    bottom: 28%;
                }
            }

            .chartWeightCurrent {
                color: #000000;
                top: 3%;
                left: 10%;
            }
        }

        .chartNotice {
            position: absolute;
            bottom: 0;
            width: calc(100% - 60px);
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #8F8E94;
            text-align: start;
        }

        .logoImage {
            width: 100%;
        }
    }
}

.lightPurpleContainer {
    .swiperSlide {
        background-color: #FFFFFF;
    }

    .swiperCard {
        .chartNotice {
            bottom: 10px;
        }
    }
}

.yellowContainer {
    --swiper-pagination-bullet-inactive-color: #D9D9D9;
}