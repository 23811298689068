@import '/src/styles/variables.scss';

.mainContainer{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;

    .imageContainer {
        min-height: 100vh;
        background-color: #8e8b7e;

        .imageBox {
            min-width: 375px;
            max-width: 425px;
            margin: auto;
            height: 100%;
        }

        .backgroundImage {
            width: 100%;
        }
    }
}
