@import '/src/styles/variables';

.mealPlanBonus{

    .mealPlanBonusRef {
        height: 1px;
        margin: 15px 0;
    }

    .mealPlanBonusAdvantages {
        padding: 20px;
        background-color: #F4F4F4;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .mealPlanBonusAdvantagesGroup {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
            align-content: flex-start;
        }

        .mealPlanBonusAdvantage {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: flex-start;

            .advantagePoint {
                text-align: left;
                font-weight: 600;
                font-size: 15px;
            }
        }
    }

    .mealPlanBonusBox {
        padding: 20px;

        .mealPlanBonusContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 15px;
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);

            .redText {
                color: #E15768;
                font-weight: 700;
            }

            .mealPlanBonusContent {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-self: center;

                .mealPlanBonusTitle {
                    margin-left: 10px;
                }

                .mealPlanBonusNutrition {
                    max-width: 200px;
                }

                h2 {
                    text-align: left;
                    font-size: 22px;
                }

                .giftImage {
                    background-color: #FFF2F0;
                    border-radius: 50%;
                    width: 60px;
                    height: 60px;
                    margin: auto 0;
                    display: flex;

                    img {
                        max-width: 90%;
                        margin: auto;

                    }
                }
            }
            @media screen and (min-width: 400px) {
                .mealPlanBonusContent {
                    align-self: unset;

                    .giftImage {
                        margin: auto;
                        min-width: 80px;
                        min-height: 80px;
                    }

                    .mealPlanBonusTitle {
                        margin: auto;
                    }
                }
            }
        }

        .bFContainerBox {
            position: relative;
            box-shadow: unset;
            border: 1px solid #000000;

            .bFGiftImage {
               position: absolute;
               top: 0;
               width: 75px;
            }

            .bFGiftImage1 {
                            position: absolute;
                           // top: 0;
                          //  width: 75px;
                           height: 75px;
            }

            .mealPlanBonusContent {
                h2 {
                    font-size: 20px;
                }

                .mealPlanBonusTitle {
                     margin-left: 90px;
                }
            }

            @media screen and (min-width: 400px) {
                .mealPlanBonusContent {
                    .mealPlanBonusTitle {
                        margin-left: 90px;
                    }
                }
            }
        }

        .nYContainerBox {
            position: relative;
            border: 1px solid #2D603F;
            background-color: unset;
            box-sizing: border-box;

            .redText {
                -webkit-text-stroke: 0.5px #ffffff;
                font-weight: 800;
                font-size: 22px;
                line-height: 24px;
                color: #B43127;
            }

            .boxBackgroundImage {
                position: absolute;
                z-index: 1;
                top: -1px;
                left: -5px;
                width: calc(100vw - 32px);
                max-width: 429px;
                height: calc(100% + 11px);
            }

            .mealPlanBonusContent {
                z-index: 2;

                h2 {
                    -webkit-text-stroke: 0.5px #ffffff;
                    color: #2D603F;
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 25px;
                }
            }
        }
    }
}
