@import '/src/styles/variables.scss';

.container {
    width: 210px;
    height: 210px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    .videoContainer {
        background-color: #000000;
        width: 100%;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .muteButton {
        position: absolute;
        bottom: -5px;
        left: 105px;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border: none;
        cursor: pointer;

        .image {
            width: 100%;
            height: 100%;
        }
    }
}

.circleContainer {
}

.squareContainer {
    height: 100%;
    min-width: 335px;
    min-height: 335px;
    max-width: 385px;
    max-height: 385px;
    border-radius: 10%;

    .muteButton {
        left: 50%;
    }
}

.rectangleContainer {
    height: 260px;
    width: 100%;
    min-width: 182px;
    border-radius: 0 20px 20px 0;
}

.fullScreenContainer{
    width: 100%;
    border-radius: unset;
     max-width: unset;
}
