html {
    box-sizing: border-box;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

ul[class],
ol[class] {
    padding: 0;
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

ul[class] {
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
    box-shadow: none !important;

    &:focus, &:active {
        box-shadow: none;
        outline: none !important;
        outline-offset: 0 !important; 
    }
}

a {
    text-decoration: none !important;
}  