.mainContainer {
    margin: 0 auto;
    text-align: center;
    background: #F9FAFF;

    .rateNote {
        margin: 14px 0 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #818181;
    }

    .resultsContainer {
        padding: 22px 0 41px;
        height: 852px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);

        h2 {
            padding: 0 21px 19px;
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            color: #000;

            span {
                color: #E15768;
            }
        }
    }

    .disclaimer {
        padding: 0 35px;
        margin-bottom: 13px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #BBBBBB;

        b {
            font-weight: 700;
        }
    }

    .questionsTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        margin-top: 15px;
    }

    .questionContainer {
        padding: 0 20px;
        margin-top: 19px;

        .titleContainer {
            img {
                width: 18px;
                height: 18px;
                margin-right: 12px;
            }

            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: left;
            }
        }

        .subtitle {
            margin: 9px 0 0 30px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            opacity: 0.4;
            text-align: start;
        }
    }

    .marginFeedbackTitle {
        margin-top: 66px;
    }

    .commentContainer {
        margin: 19px 20px 0;
        padding: 25px 30px 29px 21px;
        background: #fff;
        box-shadow: 0px 3px 15px rgba(97, 175, 218, 0.15);
        border-radius: 8px;

        .titleContainer {
            h2 {
                text-align: start;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
                margin-bottom: 3px;
            }

            img {
                width: 89px;
                height: 13.7px;
                margin-bottom: 19px;
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            opacity: 0.4;
            text-align: start;
            margin-bottom: 28.5px;
        }

        .authorContainer {
            span {
                font-weight: 400;
                font-size: 11px;
                line-height: 13px;
                opacity: 0.4;
            }
        }
    }

    .guaranteeNote {
        padding: 0 20px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #BBBBBB;
        margin-bottom: 57px;

        b {
            font-weight: 700;
        }
    }

    .bottomSpace {
        margin-bottom: 40px;
    }
}

.defaultStyle {
    padding: 0 0 100px;
    max-width: 460px;
    font-family: 'Roboto', sans-serif;

    .logoContainer {
        padding: 14px 20px 0;
        margin-bottom: 10px;

        .timerContainer {

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #818181;
            }

            span {
                font-weight: 900;
                font-size: 18px;
                line-height: 110%;
                color: #E15768;
            }
        }
    }

    .selectedDataContainer {
        padding: 0 20px;
        margin: 10px 0 10px;

        .selectedItem {
            .circleContainer {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 4px;
                border: double 4px transparent;
                border-radius: 50%;
                background-image: linear-gradient(white, white), radial-gradient(circle at center top, #fd6c59, #dd3787);
                background-origin: border-box;
                background-clip: content-box, border-box;
                display: inline-block;
                box-shadow: 0px 4px 20px rgba(225, 87, 104, 0.3);

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 16px;
                    height: 16px;
                }
            }

            .itemText {
                align-items: flex-start;

                .label {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #818181;
                }

                .content {
                    font-weight: 800;
                    font-size: 15px;
                    line-height: 17px;
                    color: #1B1D22;
                }
            }

            &:last-child {
                margin-right: 30px;
            }
        }

        .verticalLine {
            height: 40px;
            border: 1px solid #818181;
        }
    }

    .title1 {
        margin: 10px 20px 10px;
        font-weight: 800;
        font-size: 22px;
        line-height: 27px;
        text-transform: uppercase;

        span {
            color: #E15768;
        }
    }

    .resultsContainer {
        margin: 0 20px 43px;
        width: calc(100% - 40px);
    }

    .titleProgramDescription {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #000;
        margin: 10px 0 16px;
    }

    .descriptionContainer {
        position: relative;
        margin: 0 20px 25px;
        padding: 13px 9px 13px 13px;
        border-radius: 10px;
        background: linear-gradient(#fcf9f2, #fcf9f2) padding-box,
            linear-gradient(180deg, #F9C924 0%, #FFC215 32%, #FFF98C 68%, #FFD440 100%) border-box;
        border: 1px solid transparent;

        .itemContainer {
            margin-bottom: 7px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
                background: transparent;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .marginTitle {
        margin: 43px 0 0;
    }

    .featuresImage {
        width: 100%;
        height: 74px;

        img {
            width: 100%;
            height: 74px;
            margin: 21px 0 46px;
            padding: 0 20px;
        }
    }
}

.lightGreenStyle {
    min-width: 375px;
    max-width: 425px;
    padding: 20px;
    position: relative;

    .logoContainer{
        margin-top: 20px;
        max-height: 15px;
        width: 100%;

        .logoImage {
            margin-left: auto;
            margin-right: auto;
            height: 15px;
        }
    }

    .beforeTitle {
        margin-top: 20px;
        font-weight: 700;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        color: #000000;
    }

    .title {
        font-weight: 800;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #000000;
    }

    .cfTitle {
        font-size: 21px;
    }

    .planTitle {
        margin: 20px 0;
        font-weight: 800;
        font-size: 22px;
        line-height: 27px;
        color: #000000;

        span {
            color: #E15768;
        }
    }

    .cfPlanTitle {
        margin: 20px -20px;
    }

    .swiperArea {
        margin: 15px -20px 5px -20px;
    }

    .videoArea {
            margin-top: 15px;
            margin-bottom: 15px;
    }

    .notifyText {
        padding: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #818181;
    }

    .disclaimer {
        margin: 20px -20px;
    }

    .questionsTitle {
        margin-top: 20px;
        color: #000000;
    }

    .questionContainer {
        padding: 0;
        margin-top: 19px;

        .titleContainer {
            img {
                margin-right: 10px;
            }
        }

        .subtitle {
            margin: 8px 0 0 28px;
        }
    }

    .commentContainer {
        margin: 19px 0 0;
        padding: 25px 21px 29px 21px;
    }

    .guaranteeNote {
        padding: 0;
        margin-bottom: 40px;
    }
}

.lightPurpleContainer {
    background-color: #FC8C18;

    .logoContainer {
        margin-top: 30px;
    }

    .planTitle {
        span {
            color: #B826F6;
        }
    }

    .commentContainer {
        background-color: #D4D9FC;
    }
}


.yellowContainer {
    background-color: #1F2022;

    .logoContainer {
        margin-top: 30px;
    }

    .beforeTitle {
        color: #FFFFFF;
    }

    .title {
        color: #FFFFFF;
    }

    .planTitle {
        color: #FFFFFF;

        span {
            color: #DAFF21;
        }
    }

    .questionsTitle {
        color: #FFFFFF;
    }

    .questionContainer {
        .titleContainer {
            color: #FFFFFF;
        }

        .subtitle {
            color: #FFFFFF;
            opacity: 1;
        }
    }

    .commentContainer {
        background-color: #D4D9FC;
    }
}

.lightBlackFridayContainer {
      .planTitle {

        span {
            color: #F81F20;
        }
    }
}