@import '/src/styles/variables';

.refundBox {
    padding: 0 20px;

    .refundBoxContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);

        .refundBoxContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .refundBoxTitle {
                margin: auto;
            }

            p {
                width: 100%;
                text-align: left;
                font-size: 18px;
            }

            .boldText {
                font-weight: 700;
            }

            .cupImage {
                background-color: #FFF2F0;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                margin: auto 0;
                display: flex;

                img {
                    width: 60%;
                    margin: auto;
                }
            }
        }
        @media screen and (min-width: 400px) {
            .refundBoxContent {
                align-self: unset;

                .cupImage {
                    margin: auto;
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }
}

.refundBoxText {
    padding: 20px;
    font-weight: 700;

    .redText {
        color: #E15768;
    }
}

.notifyBox {
    align-items: start;
    width: 100%;
    padding-bottom: 20px;

    .notifyImageBox {
        margin: 0 10px;
        max-height: 25px;
        max-width: 25px;

        .notifyImage {
            height: 100%;
            width: 100%;
        }
    }

    .infoBox {
        align-items: start;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;

        span {
            margin-top: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #818181;
        }
    }
}

.yellowContainer {
    .infoBox {
        color: #FFFFFF;
    }
}

