.mainContainer {
    background-color: #fff;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    .logoImage{
        margin-top: 20px;
    }
    .formHeader{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: stretch;
        justify-content: space-around;
        align-items: center;
    }

    .back{
     width: 20px;
     height: 20px;
     position: fixed;
     top: 20px;
     left: 20px;
    }

    .title {
        font-weight: 700;
        font-size: 26px;
        line-height: 26px;
        color: #000000;
        margin-top: 30px;
        text-align: center;
    }

    .subtitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        color: #818181;
        margin-top: 15px;
    }
    .buttonImage{
      display: inline-block;
      float:left;
      max-width: 6%;
    }

    .formContainer {
        width: 100%;
        padding: 20px 20px 40px 20px;

        .errorContainer {
            position: relative;
            height: 20px;
            width: 100%;
            padding: 10px;

            .defaultMessage {
                position: absolute;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
            }

            .errorMessage {
                color: #E25869;
            }

            .infoMessage {
                color: #2FAE00;
            }
        }
    }

    .buttonContainer {
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
        width: calc(100% - 40px);
        height: 48px;
        padding: 0 24px;
        border: none;
        border-radius: 8px;
        color: #fff;
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
        background: linear-gradient(353deg, rgba(221,55,135,1) 5.23%, rgba(253,108,89,1) 83.78%);
        box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3) !important;
        cursor: pointer;
        z-index: 1;
    }

    .optionContainer {
            margin-top: 15px;
            margin-bottom: 15px;
            position: relative;
            width: calc(100% - 10px);
            height: 48px;
            padding: 0 24px;
            border: 1px solid #000000;
            border-radius: 8px;
            color: #fff;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            background: white;
            cursor: pointer;
            z-index: 1;
            display: inline;

             .optionText {
                  color: #000000;
                }

        }

    .disabledButton {
        background: #FDDEE1;
    }

    .agreements {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #BBBBBB;

        .agreementLinks {
            margin-top: 2px;
        }

        a {
            margin-left: 5px;
            color: #000000;
        }
    }
}