.stepSliderContainerMini {
    position: relative;

    .MuiSlider-rail {
        height: 20px !important;
    }

    .MuiSlider-root {
        width: 315px !important;
    }

    .MuiSlider-rail {
        background-color: transparent !important;
    }

    .MuiSlider-mark {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        background-color: #fff !important;
        z-index: 6 !important;
        opacity: 1 !important;
    }

    .MuiSlider-mark[data-index='0'] {
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 0;
            width: 105px;
            height: 20px;
            z-index: 5;
            background-color: #FF8550;
            border-radius: 30px 0 0 30px;
        }
    }

    .MuiSlider-mark[data-index='1'] {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            z-index: 5;
            background-color: #fff;
            border-radius: 50%;
        }

        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: -53px;
            width: 110px;
            height: 20px;
            z-index: 5;
            background-color: #FFC351;
        }
    }

    .MuiSlider-mark[data-index='2'] {

        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: -100px;
            width: 100px;
            height: 20px;
            z-index: 5;
            background-color: #5CD3C6;
            border-radius: 0 30px 30px 0;
        }
    }

    .MuiSlider-mark[data-index='0'], .MuiSlider-mark[data-index='2'] {
        background-color: transparent !important;
    }

    .MuiSlider-thumb {
        width: 44px !important;
        height: 44px !important;
        border: 13.2px solid #fff;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        background-color: #E15768 !important;
        z-index: 9;

        &:hover, &:focus, &.focusVisible {
            box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
        }

        &::before {
            box-shadow: none !important;
        }
    }

    .Mui-focusVisible {
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
    }

    .MuiSlider-track {
        display: none !important;
    }

    .Mui-active {
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
    }

    .orange {
        .MuiSlider-thumb {
            background-color: #FF8550 !important;
        }
    }

    .yellow {
        .MuiSlider-thumb {
            background-color: #FFC351 !important;
        }
    }

    .blue {
        .MuiSlider-thumb {
            background-color: #5CD3C6 !important;
        }
    }
}

.stepSliderContainer {
    position: relative;

    .MuiSlider-rail {
        height: 20px !important;
    }
    
    .MuiSlider-root {
        width: 315px !important;
    }
    
    .MuiSlider-rail {
       background-color: transparent !important;
    }
    
    .MuiSlider-mark {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        background-color: #fff !important;
        z-index: 6 !important;
        opacity: 1 !important;
    }
    
    .MuiSlider-mark[data-index='0'] {
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 0;
            width: 68px;
            height: 20px;
            z-index: 5;
            background-color: #E15768;
            border-radius: 30px 0px 0px 30px;
        }
    }
    
    .MuiSlider-mark[data-index='1'] {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            z-index: 5;
            background-color: #fff;
            border-radius: 50%;
        }
    
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 50%;
            width: 63px;
            height: 20px;
            z-index: 5;
            background-color: #FF8550;
        }
    }
    
    .MuiSlider-mark[data-index='2'] {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            z-index: 5;
            background-color: #fff;
            border-radius: 50%;
        }
    
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 50%;
            width: 63px;
            height: 20px;
            z-index: 5;
            background-color: #FFC351;
        }
    }
    
    .MuiSlider-mark[data-index='3'] {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            z-index: 5;
            background-color: #fff;
            border-radius: 50%;
        }
    
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 50%;
            width: 63px;
            height: 20px;
            z-index: 5;
            background-color: #ADDE9B;
        }
    }
    
    .MuiSlider-mark[data-index='4'] {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            z-index: 5;
            background-color: #fff;
            border-radius: 50%;
        }
    
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 50%;
            width: 58px;
            height: 20px;
            z-index: 5;
            background-color: #5CD3C6;
            border-radius: 0 30px 30px 0;
        }
    }

    .MuiSlider-mark[data-index='0'], .MuiSlider-mark[data-index='5'] {
        background-color: transparent !important;
    }

    .MuiSlider-thumb {
        width: 44px !important;
        height: 44px !important;
        border: 13.2px solid #fff;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        background-color: #E15768 !important;
        z-index: 9;
    
        &:hover, &:focus, &.focusVisible {
            box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
        }
    
        &::before {
            box-shadow: none !important;
        }
    }
    
    .Mui-focusVisible {
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
    }
    
    .MuiSlider-track {
        display: none !important;
    }
    
    .Mui-active {
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
    }

    .red {
        .MuiSlider-thumb {
            background-color: #E15768 !important;
        }
    }
    
    .orange {
        .MuiSlider-thumb {
            background-color: #FF8550 !important;
        }
    }
    
    .yellow {
        .MuiSlider-thumb {
            background-color: #FFC351 !important;
        }
    }
    
    .green {
        .MuiSlider-thumb {
            background-color: #ADDE9B !important;
        }
    }
    
    .blue {
        .MuiSlider-thumb {
            background-color: #5CD3C6 !important;
        }
    }
}