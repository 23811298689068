.StripeElement {
    margin-bottom: 4px;
    border-radius: 8px;
}

.paypal-button-container {
    height: 48px;
}

.recurly-element {
    height: 50px;
    border: 1px solid #BBBBBB;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    color: #BBBBBB;
    border-radius: 8px;
    max-width: 100%;
    min-width: 60px;
}

#google-pay-button-container {
    height: 48px;
    min-width: 163px;

    .gpay-card-info-container {
        min-width: 163px;
        min-height: 48px;
        height: 48px;
    }

    .gpay-card-info-iframe {
        min-width: 163px;
    }

}

.paymentContainer {
    .firstLine {
        justify-content: space-between;
    }

    input {
        border: none;
    }

    .fieldCardHolderFirstName {
        font-weight: 400;
        padding-left: 8px;
        min-width: 49%;
        color: #000;

        &::placeholder {
            text-transform: capitalize;
            color: #BBBBBB;
            font-weight: 500;
            font-size: 13px;
            line-height: 120%;
        }

        &:focus {
            outline: 0;
        }
    }

    .fieldCardHolderLastName {
        font-weight: 400;
        padding-left: 8px;
        min-width: 49%;
        color: #000;

        &::placeholder {
            text-transform: capitalize;
            color: #BBBBBB;
            font-weight: 500;
            font-size: 13px;
            line-height: 120%;
        }

        &:focus {
            outline: 0;
        }
    }

}

.buttonsPaymentContainer {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .StripeElement {
        width: 200px;
    }

    .applePay {
        width: 163px;
        height: 48px;
        border-radius: 8px;
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-type: buy; /* Use any supported button type. */
        -apple-pay-button-style: black;
    }

    .paypal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 48px;
        border: none;
        background: #FFC43A;
        box-shadow: 0 4px 20px 0 rgba(225, 87, 104, 0.30);
        border-radius: 4px;
        min-width: 163px;
        position: relative;

        .title {
            font-size: 14px;
            color: #505050;
            vertical-align: baseline;
            text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
        }

        .logo {
            font-family: Verdana, Tahoma;
            font-weight: bold;
            font-size: 26px;
            display: inline-block;
            font-size: 20px;
            width: 100%;
            text-align: center;

            .first {
                color: #073783;
            }

            .last {
                color: #2694B7;
            }
        }
    }

    .googlePay {
        height: 48px;
        border-radius: 8px;
        padding: 15px 30px;
        border-radius: 5px;
        background-color: #000000;
        margin: 0 auto;
        display: block;
        min-width: 178px;
        position: relative;

        .logo {
            font-family: Verdana;
            justify-content: flex-start;
            font-size: 14px;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(3, calc(33.33% - 10px));

            .first {
                font-style: normal;
                color: #ffffff;
                margin: 0;
            }

            .last {
                font-style: normal;
                color: #ffffff;
                margin: 0;
            }

            .googleIcon {
                margin: 0;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}