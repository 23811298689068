@import '/src/styles/variables';

.discountBox {
    padding: 20px;

    .discountContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 14px 24px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 3px 15px rgba(97, 175, 218, 0.15);

        .oldDiscount {
            text-align: left;

            .text {
                font-size: 18px;
                font-weight: 400;
                display: inline-block;
                position: relative;
            }

            .line {
                position: absolute;
                width: calc(100% + 4px);
                border: 0.5px solid #000000;
                top: 50%;
                left: 0;
                transform: translate(-2px, -50%);
            }
        }
        .percent {
            color: #E15768;
            font-weight: 700;
        }
        .newDiscount {
            display: flex;
            flex-direction: row;
            margin-top: 15px;

            h2 {
                text-align: left;
                font-size: 28px;
            }

            .giftImage {
                width: 75%;
            }
        }
    }
}

.lightGreenCampaign {
    padding: 20px 0;
}