.mainContainer {
    height: 100%;
    margin: 23px 0 60px;
    text-align: center;

    .cardTitle {
        padding: 0 65px;
    }

    .marginTitle {
        margin-bottom: 73px;
    }
}