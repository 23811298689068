.mainContainer {
    width: 100%;
    height: 100%;
    margin: 23px 0 20px;
    text-align: center;

    h1 {
        height: 70px;
    }

    .imageContainer {
        margin: 20px;
        background-color: #F4F8FB;
        box-sizing: border-box;
        border: 1px solid #E7E7E7;
        border-radius: 15px;
        width: calc((100vw - 40px));
        max-width: 420px;
        padding: 27px;

        .image {
            margin: auto;
            width: 200px;
            height: 200px;

            img {
                margin: auto;
                width: 200px;
                height: 200px;
            }
        }
    }

    .cardsContainer {
        display: flex;
        margin: 10px 20px;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 15px;
    }
}
