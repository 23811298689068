@import '/src/styles/variables.scss';

.cardContainer {
    position: relative;
    width: 157px;
    height: 242px;
    padding: 8px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border: 0.1px solid #d3d3d3;
    cursor: pointer;
    transition: all 0.1s;

    .cardImage {
        width: 141px;
        height: 192px;
        background: #F7F5F5;
        border-radius: $border-radius;
        border: 0.4px solid #818181;

        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: #F7F5F5;
            border-radius: $border-radius;
            z-index: 1;
        }
    }

    .cardText {
        margin-top: 11px;
        text-align: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #000;
    }

    :active::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px; 
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }
}