.mainContainer {
    width: 100%;
    margin: 23px 0;
    text-align: center;

    h1 {
        padding: 0 20px;
    }

    .imagesArea {
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        gap: 5px;

        .image {
            width: 280px;
            opacity: 0;
            animation: fadeIn 1s forwards;
        }

        @keyframes fadeIn {
            to {
                opacity: 1;
            }
        }

        .imageLeft {
            align-self: start;
        }

        .imageRight {
            align-self: end;
        }
    }
}

.yellowContainer {
    h1 {
        color: #FFFFFF;
    }
}
