@import '/src/styles/variables';

.nutritionSwitcherContainer {
    background: #000000;
    border-radius: 8px;
    margin: 0 20px 20px;
    padding: 3px 20px;

    label {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        color: #FFFFFF;
        justify-content: space-between;
        margin-left: 0;
    }
}
