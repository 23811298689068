.mainContainer {
    background-color: #fff;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    .imageContainer {
        position: relative;
        width: 100%;

        .logoImage{
            margin: 20px auto 0 auto;
        }

        .skipButton {
            top: 20px;
            right: 5px;
            position: absolute;
            gap: 2px;
            justify-content: center;
            cursor: pointer;

            .buttonText {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: #BBBBBB;
            }

            .skipIcon {
                width: 20px;
                height: 20px;
            }
        }
    }
}