@import '/src/styles/variables.scss';

.mainContainer {
    margin: 0 -20px;
    padding: 20px 0;
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);

    .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }

    .feedbacksContainer {
        width: 100%;

        .scrollableArea {
            padding: 10px;
            width: 100%;
            overflow-x: scroll;
            gap: 20px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .feedbackContainer {
            padding: 0 10px;

            &:first-child {
                padding-left: 70px;
            }

            &:last-child {
                padding-right: 70px;
            }

            .feedbackName {
                margin-top: 20px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #000000;
            }
        }
    }
}

.singleMainContainer {
    width: 100%;

    .feedbackLeftContainer {
        width: 100%;
    }

    .feedbacksContainer {
        width: 100%;

        .scrollableArea {
            padding: 10px;
            width: 100%;
            overflow-x: scroll;
            gap: 20px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            .feedbackVideo {
                width: 100%;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .fullScreenContainer{
        height: 100%;
        width: 100%;
    }
}

.yellowContainer {
    background: #202123;
    box-shadow: 3px 3px 15px 4px rgba(218, 255, 33, 0.15);

    .title {
        color: #FFFFFF;
    }

    .feedbacksContainer {
        .feedbackContainer {
            .feedbackName {
                color: #FFFFFF;
            }
        }
    }
}