.mainContainer {
    width: 100%;
    margin: 23px 0;
    text-align: center;

    h1 {
        padding: 0 20px;
    }

    .imageBanner {
        margin-top: 10px;
        padding: 10px 0;
        width: 270px;
    }

    .pointsBox {
        padding: 0 20px;
        width: 100%;
    }
}

.lightPurpleContainer {
}

.yellowContainer {
    h1 {
        color: #FFFFFF;
    }
}
