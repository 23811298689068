@import '/src/styles/variables.scss';

.greenSwiper {
    max-height: 440px;
    max-width: 385px;
    padding-bottom: 30px;
    --swiper-pagination-bottom: 0;
    --swiper-pagination-color: #818181;
    --swiper-pagination-bullet-size: 6px;
}

.swiperSlide {
    height: 364px;
    display: flex;
    justify-content: center;
}

.swiperCard {
    position: relative;
    max-height: 364px;
    max-width: 175px;

    .logoImage {
        height: 100%;
        width: 100%;
    }
}

.yellowContainer {
    --swiper-pagination-bullet-inactive-color: #D9D9D9;
}
