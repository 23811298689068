.mainContainer {
    margin: 23px 0;
    text-align: center;

    .title {
        padding: 0 40px;
    }

    .marginTitle {
        margin-bottom: 128px;
    }

    .cardContainer {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 15px;
    }
}

.lightPurpleContainer {
    .marginTitle {
        color: #000000;
    }
}

.yellowContainer {
    h1 {
        color: #FFFFFF;
    }

    .cardContainer > div {
        background-color: #FFFFFF;
    }
}