.swiper-area {
    .nutritionComments {
        width: 311px !important;
        height: 100% !important;

        .swiper-slide {
            width: 275px !important;
            height: 100% !important;
            border: none !important;
            box-shadow: none;
        }

        .swiper-button-next, .swiper-button-prev {
            top: 100px !important;
            color: #000 !important;
            width: 8px;
            height: 15px;
            margin-top: 0;

            &::after {
                font-size: 15px !important;
            }
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-pagination {
            display: none;
        }
    }
}