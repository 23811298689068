@import '/src/styles/variables';

.startTrialBox {
    padding: 20px;

    .startTrialContainer {
        display: flex;
        width: 100%;
        padding: 14px 24px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 3px 15px rgba(97, 175, 218, 0.15);

        .redText {
            color: #E15768;
            font-weight: 700;
        }

        .redTextPrev {
            color: #E15768;
            font-weight: 700;
            display: inline-block;
            white-space: nowrap;
            text-decoration: line-through;
        }

        .textPrev {
                    font-size: 16px;
                    font-weight: 400;
                    display: inline-block;
                    white-space: nowrap;
                    text-decoration: line-through;
        }

        .startTrial {
            display: flex;
            margin: 15px 0;

            .discountInfo {
                    flex-direction: column;
               }

            h1 {
               display: inline-block;
               white-space: nowrap;
               width: 100%;
               text-align: left;
               font-size: 16px;
               line-height: 33px;
               font-weight: 400;
               text-decoration: line-through;
            }

            h2 {
                width: 100%;
                text-align: left;
                font-size: 28px;
                line-height: 33px;
            }

            h1 span {
                white-space: nowrap;
            }

            .giftImage {
                width: 60%;
                margin: auto;
            }
        }
    }
}

.lightGreenCampaign {
    padding: 20px 0;
}
