.resultsContainer {
    .mySwiper {
        width: 311px !important;
        height: 100% !important;

        .swiper-slide {
            width: 275px !important;
            height: 100% !important;
            border: none !important;
            box-shadow: none;
        }

        .swiper-button-next, .swiper-button-prev {
            top: 100px !important;
            color: #000 !important;
            width: 8px;
            height: 15px;
            margin-top: 0;

            &::after {
                font-size: 15px !important;
            }
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-pagination-horizontal {
            bottom: 0 !important;
        }

        .swiper-pagination-bullet {
            margin-left: 19px !important;
            margin-right: 0 !important;
            border-radius: 5px !important;
            background: #000;
            opacity: 0.1;
            width: 40px !important;
            height: 2px !important;

            &:first-child {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }

        .swiper-pagination-bullet-active {
            height: 4px !important;
            background: linear-gradient(90deg, #E15768, #FFAD32) !important;
            opacity: 1;
        }
    }
}