.mainContainer {
    text-align: center;
    margin-top: 13px;

    .subtitle {
        padding: 5px 44px 20px;
        font-size: 15px;
        color: #818181;
        line-height: 18px;
    }

    .text {
        padding: 0 90px;
        margin: 15px 0 40px;
    }

    .circleContainer {
        position: relative;
        margin: 37px 0;
        width: 275px;
        height: 275px;
        border-radius: 50%;
        background: #FFF8F7;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1) !important;

        .circlePercentage {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            padding: 0;
            margin: 0;
            transform: translate(-50%, -50%);
            font-weight: 700;
            font-size: 72px;
            line-height: 86px;
            width: 200px;
            background-color: #FFF8F7;
            background-image: linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%);
            text-shadow: 0 0 #00000000;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        svg {
            position: relative;
            width: 293px;
            height: 293px;
            transform: rotate(-90deg) translate(8px, -8px);

            circle {
                width: 100%;
                height: 100%;
                fill: none;
                stroke-width: 16;
                stroke-linecap: round;
                transform: translate(8px, 8px);
                stroke-dasharray: 860;
                transition: all 0.06s;
            }
        }
    }

    .textContainer {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, #FFFFFF 100%);
        }

        p {
            position: relative;
            z-index: -1;
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1B1D22;
        }

        .active {
            z-index: 999;
        }
    }

    .smallCircleContainer {
        width: 160px;
        height: 160px;
        margin: 20px 0 15px 0;

        .circlePercentage {
            font-size: 45px;
            line-height: 45px;
            width: 120px;
            top: 47%;
        }

        svg {
            width: 175px;
            height: 175px;

            circle {
                stroke-width: 12;
                stroke-dasharray: 500;
            }
        }
    }

    .infoContainer {
        margin-top: 15px;
        text-align: center;
        width: 100%;

        .infoLine {
            margin: 0 24px;
            border-top: 0.4px solid rgba(0, 0, 0, 0.12);
        }

        .infoTitle {
            padding-top: 15px;
            font-size: 32px;
            color: #E15768;
            font-weight: 700;
        }
        .infoSubtitle {
            font-weight: 700;
        }
    }

    .commentsContainer {
        margin: 20px 24px 0 24px;
        max-width: 360px;

        .active {
            display: block !important;
        }

        .commentContainer {
            display: none;
            padding: 25px 30px 29px 21px;
            background: #fff;
            box-shadow: 0px 3px 15px rgba(97, 175, 218, 0.15);
            border-radius: 8px;

            .titleContainer {
                h2 {
                    text-align: start;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 23px;
                    margin-bottom: 3px;
                }

                img {
                    width: 89px;
                    height: 13.7px;
                    margin-bottom: 19px;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                opacity: 0.4;
                text-align: start;
                margin-bottom: 28.5px;
            }

            .authorContainer {
                span {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    opacity: 0.4;
                }
            }
        }
    }
}