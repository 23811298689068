@import '/src/styles/variables.scss';

.cardContainer {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: 60px;
    padding: 10px;
    align-items: center;
    justify-content: center;

    .cardTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 26px;
        color: #000000;
        text-align: center;
        padding: 7px 0;
    }
}

.activeCard {
    &::before {
        content: "";
        position: absolute;
        top: -0.4px;
        right: -0.4px;
        bottom: -0.4px;
        left: -0.4px; 
        border: 1px solid #E15768;
        box-shadow: $box-shadow-active;
        border-radius: $border-radius;
    }
}
