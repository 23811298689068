.mainContainer {
    background-color: #fff;
    min-width: 375px;
    max-width: 425px;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    .logoImage{
        margin-top: 20px;
    }

    .title {
        font-weight: 800;
        font-size: 26px;
        line-height: 26px;
        color: #000000;
        margin-top: 30px;
        text-align: center;
    }

    .subtitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        color: #818181;
        margin-top: 15px;
    }

    .stepsContainer {
        width: 100%;
        padding: 20px;

        .step {
            padding: 10px 0;
            align-items: flex-start;

            .stepPoint {
                text-align: left;
                gap: 10px;
            }

            .stepNumber {
                align-self: flex-start;
                position: relative;
                width: 32px;
                min-width: 32px;
                height: 32px;
                border-radius: 50%;
                z-index: 4;
                background-color: #D3D4D9;
                box-shadow: 0 0 2px #D3D4D9;
                display: inline-flex;

                &::before {
                    color: #ffffff;
                    content: "1";
                    font-size: 20px;
                    font-weight: 600;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }

            .stepOne::before {
                content: "1";
            }

            .stepTwo::before {
                content: "2";
            }

            .stepThree::before {
                content: "3";
            }

            .stepTitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;

                .stepSubtitle {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 14px;
                    color: #818181;
                }

                span {
                    margin-right: 5px;
                    color: #E15768;
                    text-decoration-line: underline !important;
                    cursor: pointer;
                }
            }

            .stepContent {
                border-radius: 10px;
                margin: 15px auto;
                background-color: #F9FAFF;

                .image {
                    border-radius: 10px;
                }
            }
        }
    }

    .buttonContainer {
        margin-bottom: 15px;
        position: relative;
        width: calc(100% - 40px);
        height: 48px;
        padding: 0 24px;
        border: none;
        border-radius: 8px;
        color: #fff;
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        background: linear-gradient(353deg, rgba(221,55,135,1) 5.23%, rgba(253,108,89,1) 83.78%);
        box-shadow: 0 4px 20px rgba(225, 87, 104, 0.3) !important;
        cursor: pointer;
        z-index: 14;
    }
}